const user = {
  wallet: "",
  walletBalance: 0,
  accounts: [],
};

export const authReducer = (state = user, actions) => {
  switch (actions.type) {
    case "CONNECTWALLET":
      return { ...state, wallet: actions.payload };
    case "BALANCE":
      return { ...state, walletBalance: actions.payload };
    case "DISCONNECT":
      return { ...state, wallet: "", walletBalance: 0 };
    case "TERMSCONDITIONS":
      const accounts = state.accounts;
      let index = accounts.findIndex((obj) => obj.wallet === state.wallet);

      if (index === -1) {
        accounts.push({ wallet: state.wallet, termsConditions: true });
      }
      return { ...state, accounts };

    default:
      return state;
  }
};
