import React from "react";
import { connectWallet } from "../utils";
import { useDispatch, useSelector } from "react-redux";

const rabbitIcon = "/assets/rabbit.png";
const metamaskIcon = "/assets/MetaMask.png";
export function Connectwallet() {
  const wallet = useSelector((state) => state.auth.wallet);
  const dispatch = useDispatch();

  return (
    <div className="connect-wallet-container">
      <p className="connectwallet-heading">Connect Wallet</p>
      <div className="image-container">
        <div
          className="rabbit-image-wrapper"
          onClick={() =>
            connectWallet({
              wallet,
              dispatch,
            })
          }
        >
          <img className="rabbit-icon" src={rabbitIcon} alt="rabbit" />
        </div>
        <div
          className="metamask-image-wrapper"
          onClick={() =>
            connectWallet({
              wallet,
              dispatch,
            })
          }
        >
          <img className="metamask-icon" src={metamaskIcon} alt="MetaMask" />
        </div>
      </div>
    </div>
  );
}
