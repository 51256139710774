const id_config = {
  website: "6566f8690d487853ac0ce6ad",
  projectsPage: "64d66ab72aed52cfe16de6ad",
  faqsPage: "64d5d7702aed52cfe16de500",
  googleSheetId: "656714a70d487853ac0ce9fc",
  teamPage: "64dca52429dd1fa13462fb6c",
  corsId: "656764d70d487853ac0ceade",
  apiKey: process.env.REACT_APP_NEXAFLOW_API_KEY,
  apiUrl: process.env.REACT_APP_NEXAFLOW_API_URL,
};
export { id_config };
