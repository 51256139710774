import "./footer.css";
import { getIcon } from "../../../Icon";
import { Popup } from "../../Popup/popup";
import { useEffect, useState, useRef } from "react";
import { TabHeadings } from "../../Popup/Tab";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import NearMeIcon from "@mui/icons-material/NearMe";
import { NotifyUser } from "../../../router";
import { LoadingOutlined } from "@ant-design/icons";
import credits from "./credits.json";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { id_config } from "../../../id_config";

export default function Footer() {
  const social = [
    { name: "Twitter", url: "https://twitter.com/solarpunkdao" },
    { name: "Medium", url: "https://medium.com/@SolarpunkDao" },
    { name: "Discord", url: "https://discord.com/invite/KS8jDkZB5R" },
    { name: "GitBook", url: "https://solarpunkdao.gitbook.io/earth-coin/" },
    { name: "Email", mail: "progress@solarpunkdao.earth" },
  ];

  let formLink = "https://forms.gle/kSk1cFHa2atDEgCZ9";
  const polygon = "https://polygon.technology/";
  const jobOpening = "https://angel.co/company/earth-coin-1/jobs";
  const disclaimerLink = process.env.REACT_APP_DISCLAIMER_URL;

  const [showPopup, setShowPopup] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [email, setEmail] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      return;
    } else {
      setLoading(true);
    }

    const validate = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (!validate.test(email)) {
      NotifyUser({ message: "Invalid Email", type: "error" });
      setLoading(false);
      return;
    } else {
      try {
        const getresponse = await axios({
          url: `${id_config.apiUrl}/api/googleSheets/${id_config.googleSheetId}`,
          method: "GET",
          headers: {
            "x-api-key": id_config.apiKey,
          },
        });
        const getSubscriberList = getresponse.data;
        if (getSubscriberList && getSubscriberList.length) {
          setLoading(true);
          const checkSubscriber = []
            .concat(...getSubscriberList)
            ?.includes(email);

          if (checkSubscriber) {
            NotifyUser({
              message: "Subscription already active",
              type: "error",
            });
            setLoading(false);
            return;
          }
        }
        const dataArray = !getSubscriberList ? [["Email"], [email]] : [[email]];

        const response = await axios.post(
          `${id_config.apiUrl}/api/googleSheets/${id_config.googleSheetId}`,
          {
            data: dataArray,
          },
          {
            headers: {
              "x-api-key": id_config.apiKey,
              "Content-Type": "application/json",
            },
          }
        );

        setEmail("");
        NotifyUser({
          message: "Email submitted successfully",
          type: "success",
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);

        NotifyUser({ message: "Email failed to submit", type: "error" });
      }
    }
  };

  return (
    <footer className='footer-container'>
      <section className='footer-section-1'>
        {showPopup ? (
          <Popup setShowPopup={setShowPopup} Component={FooterPopupContent} />
        ) : (
          ""
        )}
        {showCredits ? (
          <Popup
            setShowPopup={setShowCredits}
            showPopup={showCredits}
            Component={FooterCreditsContent}
          />
        ) : (
          ""
        )}
        <div className='footer-section-row-1'>
          <p className='footer-heading'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href='#'>Climate Solution seeking Funds?</a>
            <span>
              <a href={formLink} rel='noreferrer' target='_blank'>
                {" "}
                Get in touch!
              </a>
            </span>
          </p>
        </div>

        <div className='footer-section-row-1'>
          <a
            href={jobOpening}
            rel='noreferrer'
            target='_blank'
            className='footer-heading'
          >
            Want to build the Solarpunk paradigm?{" "}
            <span>Check out job openings!</span>
          </a>
        </div>
      </section>

      <section className='footer-section-2'>
        <div className='footer-section-row-2'>
          <div className='footer-subSection'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href='#'
              rel='noreferrer'
              className='footer-subSection-heading-1'
            >
              EARTHCOIN
            </a>
            <p className='footer-subSection-heading-1-content-1'>
              Currency for the Solarpunk paradigm backed by climate solutions
            </p>
            <p
              className='footer-subSection-heading-1-content-2-heading'
              onClick={() => setShowCredits(true)}
            >
              CREDITS
            </p>
            <a
              href={disclaimerLink}
              rel='noreferrer'
              target='_blank'
              className='footer-subSection-heading-1-content-2-heading'
            >
              Disclaimer
            </a>
            <p className='footer-subSection-heading-1-content-2'>
              ©2022 Solarpunk DAO, All Rights Reserved.
            </p>
          </div>

          <div className='footer-subSection'>
            <p className='footer-content'>$EARTH is powered by</p>
            <a
              href={polygon}
              rel='noreferrer'
              target='_blank'
              className='footer-subSection-icon-container'
            >
              <img
                className='footer-icon'
                alt='polygon'
                src={getIcon("polygon")}
              />
              {/* <p className="footer-subSection-icon-heading-2">
              polygon
            </p> */}
            </a>
          </div>
        </div>

        <div className='footer-section-row-2'>
          <div className='footer-subSection'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href='#' className='footer-subSection-heading-2'>
              SOCIAL
            </a>
            <div className='social-container'>
              {social.map(({ name, url, mail }, i) => {
                return !mail ? (
                  <a
                    key={i}
                    href={url}
                    rel='noreferrer'
                    target='_blank'
                    className='ind-social'
                  >
                    <img src={getIcon(name)} alt={name} />
                    <p>{name}</p>
                  </a>
                ) : (
                  <a
                    key={i}
                    href={`mailto:${mail}`}
                    rel='noreferrer'
                    target='_blank'
                    className='ind-social'
                  >
                    <img src={getIcon(name)} alt={name} />
                    <p>{name}</p>
                  </a>
                );
              })}
            </div>
          </div>
          <div className='footer-subSection'>
            <p className='footer-getUpdates-content'>Get juicy updates from</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href='#'
              rel='noreferrer'
              className='footer-subSection-heading-2-name'
            >
              SOLARPUNKDAO
            </a>
            <input
              type='text'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='footer-field'
              placeholder='Email'
            />
            <button onClick={handleSubmit} className='footer-field-btn'>
              Submit{loading ? <LoadingOutlined /> : ""}
            </button>
          </div>
        </div>
      </section>
    </footer>
  );
}

function FooterPopupContent({ setShowPopup }) {
  let headings = ["Company", "Project"];
  const [value, setValue] = useState(0);
  const [arr, setArr] = useState(headings[0]);
  const [disablePage, setDisablePage] = useState(true);

  const [company, setCompany] = useState({
    name: "",
    companyName: "",
    companyAddress: "",
    website: "",
    email: "",
  });
  const [project, setProject] = useState({
    projectName: "",
    projectLocation: "",
    fundingRequired: "",
    projectDescription: "",
  });

  const handleNext = () => {
    setValue(1);
    setArr(headings[1]);
  };

  const handleSubmit = () => {
    setShowPopup(false);
  };

  const checkFields = (data, className) => {
    const validate = () => {
      for (let i in data) {
        if (!data[i]) {
          return i;
        }
      }
      return "";
    };

    let result = validate();

    let nextBtn = document.querySelector(className);

    if (nextBtn) {
      if (result) {
        nextBtn.disabled = true;
        nextBtn.style = `background-color: rgb(98 93 93);
            cursor: default;`;
      } else {
        nextBtn.disabled = false;
        nextBtn.style = `backgroundColor:black;
        cursor: pointer;
        `;
      }
    }

    return result;
  };

  useEffect(() => {
    if (arr === "Company") {
      let result = checkFields(company, ".footer-nxt-btn");
      if (result) {
        setDisablePage(true);
      } else {
        setDisablePage(false);
      }
    }
  }, [company, arr]);

  useEffect(() => {
    if (arr === "Project") checkFields(project, ".footer-submit-btn");
  }, [project, arr]);

  return (
    <div className='footer-popup-container'>
      <IconButton
        id='footer-popup-close-btn'
        onClick={() => setShowPopup(false)}
      >
        <CloseIcon />
      </IconButton>
      <p className='footer-popup-title'>Get funded!</p>
      <p className='footer-popup-description'>
        Fill the following form to get contacted by us for funding opportunities
      </p>

      <div className='tab-headings-container'>
        <TabHeadings
          label={headings}
          setArr={setArr}
          arr={arr}
          value={value}
          setValue={setValue}
          disablePage={disablePage}
        />

        <div className='footer-divider'></div>
      </div>

      {arr === "Company" ? (
        <div className='footer-page'>
          <p className='footer-title'>Your project data </p>
          <div className='footer-inputField-container'>
            <label>Your Name</label>
            <br />
            <input
              type='text'
              value={company.name}
              name='name'
              onChange={(e) => setCompany({ ...company, name: e.target.value })}
            />
          </div>

          <div className='footer-inputField-container'>
            <label>Company Name</label>
            <br />
            <input
              type='text'
              value={company.companyName}
              name='companyName'
              onChange={(e) =>
                setCompany({ ...company, companyName: e.target.value })
              }
            />
          </div>

          <div className='footer-inputField-container'>
            <label>Company address</label>
            <br />
            <input
              type='text'
              value={company.companyAddress}
              name='companyAddress'
              onChange={(e) =>
                setCompany({ ...company, companyAddress: e.target.value })
              }
            />
            <NearMeIcon id='footer-address-icon' />
          </div>

          <div className='footer-inputField-container'>
            <label>Website</label>
            <br />
            <input
              type='text'
              value={company.website}
              name='website'
              onChange={(e) =>
                setCompany({ ...company, website: e.target.value })
              }
            />
          </div>

          <div className='footer-inputField-container'>
            <label>Email</label>
            <br />
            <input
              type='text'
              value={company.email}
              name='email'
              onChange={(e) =>
                setCompany({ ...company, email: e.target.value })
              }
            />
          </div>

          <button
            onClick={() => setShowPopup(false)}
            className='footer-cancel-btn'
          >
            Cancel
          </button>
          <button className='footer-nxt-btn' onClick={handleNext}>
            Next
          </button>
        </div>
      ) : (
        ""
      )}

      {arr === "Project" ? (
        <div>
          <p className='footer-title'>Your project data </p>
          <div className='footer-inputField-container'>
            <label>Project Name</label>
            <br />
            <input
              type='text'
              value={project.projectName}
              name='projectName'
              onChange={(e) =>
                setProject({ ...project, projectName: e.target.value })
              }
            />
          </div>

          <div className='footer-inputField-container'>
            <label>Project Location</label>
            <br />
            <input
              type='text'
              value={project.projectLocation}
              name='projectLocation'
              onChange={(e) =>
                setProject({ ...project, projectLocation: e.target.value })
              }
            />
          </div>

          <div className='footer-inputField-container'>
            <label>Funding Required</label>
            <br />
            <input
              type='text'
              value={project.fundingRequired}
              name='fundingRequired'
              onChange={(e) =>
                setProject({ ...project, fundingRequired: e.target.value })
              }
            />
          </div>

          <div className='footer-inputField-Description-container'>
            <label>Project Description</label>
            <br />
            <textarea
              cols={50}
              rows={2}
              value={project.projectDescription}
              name='projectDescription'
              onChange={(e) =>
                setProject({ ...project, projectDescription: e.target.value })
              }
            />
          </div>

          <button
            onClick={() => setShowPopup(false)}
            className='footer-cancel-btn'
          >
            Cancel
          </button>
          <button onClick={handleSubmit} className='footer-submit-btn'>
            Submit
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export function FooterCreditsContent({ setShowPopup, showPopup }) {
  let popup = useRef(null);

  const closeOpenMenus = (e) => {
    if (popup.current && showPopup && !popup.current.contains(e.target)) {
      setShowPopup(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  return (
    <div ref={popup} className='footer-credits-popup-container'>
      <p className='footer-credits-title'>Credits</p>
      <div className='footer-credits-content'>
        {credits.map(({ title, data, link }, i) => {
          return (
            <div key={i} className='footer-credits-ind-content'>
              {link ? (
                <a
                  id='credits-title-link'
                  href={link}
                  rel='noreferrer'
                  target='_blank'
                >
                  {title}
                </a>
              ) : (
                <p>{title}</p>
              )}
              {data &&
                data.map(({ name, link }, ind) => (
                  <a key={ind} href={link} rel='noreferrer' target='_blank'>
                    {name}
                  </a>
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
