import "./dapp.css";
import { useDispatch, useSelector } from "react-redux";
import { connectWallet } from "./utils";

import { useEffect } from "react";
import { allowance, earthAmount, getBalance } from "./balance";

const profileImg = "/assets/mechanicsImage-1.png";
const metamaskIcon = "/assets/MetaMask.png";

export function Balance({ selectedHeading }) {
  const { earth, fruit, dai } = useSelector((state) => state.balance);
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.auth.wallet);

  const formatToTwoDecimalPlaces = (value) => {
    return Number(value).toFixed(2);
  };
  const formatAddress = (address) => {
    const formattedAddress =
      address.substring(0, 5) + "...." + address.substring(address.length - 5);

    return formattedAddress;
  };

  useEffect(() => {
    if (wallet) {
      getBalance();
      earthAmount();
      allowance();
    }
  }, [wallet, selectedHeading]);

  return (
    <div className='dapp-balance-container'>
      <div className='dapp-balance-profile-container'>
        {/* <img className="profileImg" src={profileImg} alt="profileimg" /> */}
        {/* <div className="balance-metamask-image-wrapper">
          <img
            className="balance-metamask-icon"
            src={metamaskIcon}
            alt="MetaMask"
          />
        </div> */}
        <p>{wallet ? formatAddress(wallet) : ""}</p>
      </div>
      <div className='dapp-balance-content-container'>
        <div className='dapp-earthBalance-container'>
          <p>{formatToTwoDecimalPlaces(earth)}</p>
          <p>$EARTH Balance</p>
        </div>
        <div className='dapp-earthBalance-container'>
          <p>{formatToTwoDecimalPlaces(fruit)}</p>
          <p>$FRUIT Balance</p>
        </div>
        <div className='dapp-earthBalance-container'>
          <p>{formatToTwoDecimalPlaces(dai)}</p>
          <p>$DAI Balance</p>
        </div>
      </div>
      <button
        onClick={() =>
          connectWallet({
            wallet,
            dispatch,
          })
        }
      >
        {wallet ? "DISCONNECT WALLET" : "CONNECT WALLET"}
      </button>
    </div>
  );
}
