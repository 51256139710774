import { TypeAnimation } from "react-type-animation";

export function AnimateText() {
  return (
    <TypeAnimation
      sequence={[
        "build solarpunk paradigm",
        2000,
        "reward climate action",
        2000,
        "value ecosystem services",
        2000,
        "fund climate solutions",
      ]}
      wrapper="span"
      cursor={true}
      repeat={Infinity}
      style={{ color: "#ff5e2a" }}
    />
  );
}
