import "./TermsConditions.css";

import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function TermsConditions() {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch({ type: "TERMSCONDITIONS" });
  };

  return (
    <div className='terms-condition-overlay'>
      <div className='terms-condition-popup'>
        <h3 className='terms-conditions-title'>
          Do you accept our Terms & Conditions
        </h3>
        <div className='terms-condition--box-wrapper'>
          <Content />

          <div className='terms-condition-checkbox-container'>
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <p>I accept the use of cookies in this app</p>
          </div>
        </div>
        <button
          className='terms-conditions-btn'
          onClick={handleClick}
          disabled={!checked}
        >
          I Accept Terms & Conditions
        </button>
      </div>
    </div>
  );
}

function Content() {
  return (
    <div className='terms-condition-box'>
      <p className='terms-condition-box-heading'>Website Terms & Conditions</p>
      <section>
        <p className='terms-condition-box-subheading'>1. Acceptance of Terms</p>
        <p>
          We at Earth Coin LLC, are dedicated to catalyzing positive global
          change through sustainable and solarpunk initiatives. Please read
          these Terms and Conditions carefully. By accessing the website of the
          Solar Punk DAO and/or any pages thereof (the "Website"), you signify
          your agreement with, and understanding of, these Terms and Conditions
          and the legal information pertaining to both this Website and any
          material on it. If you do not agree with these Terms and Conditions,
          do not access this Website.{" "}
        </p>
        <p>
          In addition, when using particular services offered through the
          Website, you and Earth Coin LLC shall be subject to any guidelines or
          rules applicable to such services that may be posted on the Website
          from time to time. All such guidelines or rules are hereby
          incorporated by reference into these Terms and Conditions. The Earth
          Coin LLC reserves the right to change these Terms and Conditions at
          any time without notice to you. You are therefore responsible for
          regularly reviewing these Terms and Conditions. Continued use of this
          Website following any such changes shall constitute your acceptance of
          such changes.{" "}
        </p>
      </section>
      <section>
        <p>2.Mission and Vision</p>
        <p>
          2.1. Earth Coin LLC is committed to addressing climate change and
          fostering a solarpunk future by supporting projects that align with
          our mission. Our vision is to leverage blockchain and crypto economics
          to incentivize capital flow towards sustainable and regenerative
          solutions.
        </p>
      </section>
      <section>
        <p>3. Intellectual Property </p>
        <p>
          3.1. The Website was created by Earth Coin LLC registered under the
          Financial Services Authority of St. Vincent and Grenadines.{" "}
        </p>
        <p>
          3.2. The name “Solar Punk DAO” and its logos are registered trademarks
          of ____________ and licensed to _________________________. Besides,
          the content of the Website, including any texts, images, videos,
          software, products, services and other information published or made
          available on the Website, subject to the rights of third parties under
          contract with the Earth Coin LLC, is the exclusive property of the
          Earth Coin LLC. Various text, picture, video and data elements on the
          Website have been supplied by third parties under contract with the
          Earth Coin LLC.
        </p>
        <p>
          3.3. The Earth Coin LLC shall bear no responsibility whatsoever for
          unauthorized copying and/or use of any material property of third
          parties on the Website or their accuracy.
        </p>
        <p>
          3.4. Nothing in the Website is designed to transfer any property or
          grant any license or right to use any names, logos, pictures, videos
          or trademarks.{" "}
        </p>
        <p>
          3.5. No act of downloading or otherwise copying or reproducing from
          the Website will transfer title to you to any software or material on
          the Website. The titles on the software and the material on the
          Website (either owned by, or licensed by third parties to, the Earth
          Coin LLC) remain the exclusive property of the Earth Coin LLC, or
          their owners.{" "}
        </p>
        <p>
          3.6. Except as otherwise stated above, you are granted permission to
          view, store, print, reproduce, distribute any pages or download any
          material from the Website for personal non-commercial use provided
          only that (i) you do not modify any such page or any such material and
          (ii) you make reference to these Terms and Conditions therewith. Any
          unauthorized reproduction for public and/or commercial purposes of the
          Website or any part thereof is a violation of Earth Coin LLC’s rights.{" "}
        </p>
      </section>
      <section>
        <p>4. No Offer of Service</p>
        <p>
          4.1. Unless otherwise stated so, the material on the Website is
          intended to be for informational purposes only.{" "}
        </p>
        <p>
          4.2. The Earth Coin LLC makes no representation or warranties that the
          material on the Website is appropriate or available for use in
          jurisdictions other than St. Vincent and Grenadines.{" "}
        </p>
        <p>
          4.3. Those users who choose to access the Website from other locations
          do so at their own risk and are fully responsible for compliance with
          local laws and regulations.
        </p>
        <p>
          4.4. If you enter into transactions via the Website, a direct
          engagement is deemed to be established only between you and Earth Coin
          LLC.
        </p>
      </section>
      <section>
        <p>5. Eligibility</p>
        <p>
          5.1. To participate in the Earth Coin LLC ecosystem, you must meet the
          eligibility criteria specified by the platform. You must also adhere
          to applicable legal regulations within your jurisdiction.
        </p>
      </section>
      <section>
        <p>6. Token (Earth Coin) Issuance</p>
        <p>
          6.1. Earth Coin is a unique digital token exclusively issued by Earth
          Coin LLC. It represents our commitment to funding and supporting
          projects that contribute to the solarpunk infrastructure stack.
        </p>
        <p>
          6.2. Earth Coin is not an investment or financial instrument. It is a
          new unit of value designed to be the medium of exchange for the
          solarpunk future. Its value is linked to the climate solutions,
          ecosystem services and SolarPunk Values.
        </p>
        <p>
          6.3 Earth Coin will not be redeemed for underlying assets. Only if the
          DAO decides to wind down the project will underlying assets be sold
          and funds returned on a pro rata basis to Earth Coin holders.{" "}
        </p>
      </section>
      <section>
        <p>7. Token Minting and Backing</p>
        <p>
          7.1. Unlike traditional cryptocurrencies, Earth Coin is only minted
          against funding received for climate solutions. It is backed by
          real-world projects that contribute positively to environmental and
          social well-being.
        </p>
      </section>
      <section>
        <p>8. Climate Impact</p>
        <p>
          8.1. Earth Coin LLC cannot guarantee specific climate outcomes or
          yield return outcomes, but we commit to using funds responsibly to
          support projects that aim to address climate change and environmental
          crisis holistically.
        </p>
      </section>
      <section>
        <p>9. Projects and Partnerships</p>
        <p>
          9.1. Earth Coin LLC via any of its subsidiaries prioritizes projects
          focused on Clean Energy, Regenerative Agriculture, Clean Transport,
          Ecosystem Conservation/Regeneration, and SolarPunk Spaces. However,
          Earth Coin LLC reserves the right to evaluate and choose projects
          based on alignment with our mission.
        </p>
        <p>
          9.2. Earth Coin LLC via any of its subsidiaries aims to be a perpetual
          holding partner in these projects, earning pro rata of project
          earnings and sharing equal partnership in successes and challenges or
          even engage in debt based models if required .
        </p>
      </section>
      <section>
        <p>10. Disclaimer</p>
        <p>
          10.1. Even though the Earth Coin LLC has taken reasonable care to
          verify that the material on the Website is accurate at the time of
          publication, no representation or warranty, expressed or implied, is
          made as to its accuracy, reliability or completeness.
        </p>
        <p>
          10.2. Therefore, the Earth Coin LLC assumes no liability for the use
          or interpretation of material contained herein. Your use of the
          Website is on an "AS IS" and "AS AVAILABLE" BASIS AND AT YOUR SOLE
          RISK, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND TERMS AND
          CONDITIONS OF MERCHANTABILITY, SUITABILITY AND FITNESS FOR A
          PARTICULAR PURPOSE, OR NON INFRINGEMENT OF THIRD PARTY RIGHTS.{" "}
        </p>
        <p>
          10.3. In particular, the Earth Coin LLC assumes no responsibility for,
          and makes no guarantees that (i) the Website and services on the
          Website will meet your requirements, (ii) the access to the Website
          and its use will be enabled uninterruptedly, (iii) the services and
          functions on the Website will be uninterrupted, timely, secure or
          error-free, (iv) the results that may be obtained from the use of the
          Website and services on the Website will be accurate or reliable, (v)
          defects will be corrected, or (vi) the Website or the servers that
          make it available will be free of viruses or other harmful elements.
        </p>
        <p>
          10.4. In the event of hacking of the code behind Earth Coin or any
          tampering with the smart contract by any unfair means, there shall be
          no liability whatsoever on Earth Coin LLC or any entity or key
          managerial persons associated with it.
        </p>
        <p>
          10.5. Any material downloaded or otherwise obtained through the use of
          the Website is done at your own discretion and risk and you will be
          solely responsible for any damage you may suffer as a result of such
          downloads.{" "}
        </p>
        <p>
          10.6. ANY RESPONSIBILITY OF THE Earth Coin LLC, ITS AFFILIATES, AND
          ITS AND THEIR DIRECTORS (OR EQUIVALENT), OFFICERS, EMPLOYEES,
          REPRESENTATIVES AND AGENTS IS EXCLUDED, EXCEPT IN THE EVENT OF FRAUD
          OR WILLFUL MISCONDUCT OF THE Earth Coin LLC ITSELF.{" "}
        </p>
      </section>
      <section>
        <p>11. Links from and to the Website:</p>
        <p>
          11.1. You acknowledge and agree that the Earth Coin LLC has no
          responsibility for the accuracy or availability of information,
          material or content provided by third party linked websites, or the
          content, performance, terms and conditions of use or the privacy
          policies or practices on such websites. Links to such sites do not
          constitute an endorsement by the Earth Coin LLC of such websites or
          the content, products, or other materials or services presented on
          such websites.{" "}
        </p>
        <p>
          11.2. Information in the many web pages that are linked to the
          Website, and the applications and services available through the
          Website, comes from a variety of sources. The Earth Coin LLC does not
          author, edit, or monitor the content, links, or services provided by
          third parties.{" "}
        </p>
        <p>
          11.3. THE EARTH COIN LLC SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY
          OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED
          BY OR IN CONNECTION WITH THE USE OF OR RELIANCE ON ANY SUCH CONTENT,
          PRODUCTS OR OTHER MATERIAL AVAILABLE ON THE WEBSITE OR ON THIRD PARTY
          LINKED WEBSITES.{" "}
        </p>
        <p>
          11.4. In the event that you wish to establish a link to the Website,
          you expressly agree: a. to ask for a prior written approval by the
          Earth Coin LLC to establish a link to the Website; b. not to include
          the Website into the frame of another website and to create a direct
          link to the Earth Coin LLC's official Website; c. not to create any
          third party association with the Website; and to ensure that the link
          does not state or imply that the Earth Coin LLC, or their partners,
          sponsors and donors, endorse any other website activity, company or
          entity, or presents the Earth Coin LLC or Solar Punk DAO project in a
          false, misleading, defamatory or derogatory manner.
        </p>
        <p>
          11.5. The link to the Website does not allow the use of any of the
          Earth Coin LLC or Solar Punk DAO and/or third party content, names,
          logos, pictures or trademarks, unless separately agreed to in writing
          by the relevant rights-holder.{" "}
        </p>
      </section>
      <section>
        <p>12.Indemnity: </p>
        <p>
          12.1. As a condition of use of the Website, you agree to indemnify the
          Earth Coin LLC and its subsidiaries/affiliates, and its and theirs
          directors (or equivalent), officers, employees, agents and
          representatives from and against any and all direct or indirect
          expenses and damages arising out of claims resulting from your access
          to, or your use of the materials of, the Website.{" "}
        </p>
      </section>
      <section>
        <p>13. Limitation of Liability:</p>
        <p>
          13.1. Earth Coin LLC shall not be liable for any direct, indirect,
          incidental, consequential, or exemplary damages arising from your use
          of the platform or token.
        </p>
        <p>
          13.2. The User makes the following representations and warranties: A.
          They confirm that they are not situated, incorporated, or residing
          within a country subject to government, union, or intergovernmental
          organization embargoes. This includes entities such as the United
          States of America, the United Kingdom, the European Union, any EU
          member state, or the United Nations, or any country designated as a
          "terrorist supporting" nation by such entities. B. They confirm that
          they are not listed on any government, union, or intergovernmental
          organization's roster of individuals or entities prohibited,
          restricted, or sanctioned. C. They confirm that they do not have any
          direct or indirect ownership or control relationship with any party
          falling within the categories outlined in clauses (1) and (2) above.
        </p>
        <p>
          13.3. However, if any applicable law, rule, or regulation prohibits
          the User from accessing Earth Coin LLC, they are prohibited from doing
          so.{" "}
        </p>
      </section>
      <section>
        <p>14. Changes to Terms and Conditions</p>
        <p>
          14.1. Earth Coin LLC reserves the right to modify these terms at its
          discretion. Changes will be effective upon posting on our official
          website. Continued use of our services implies acceptance of these
          modifications.
        </p>
      </section>
      <section>
        <p>15. Privacy & Data Protection: </p>
        <p>
          15.1. Earth Coin LLC is subject to EU GDPR laws when collecting and
          processing personal data.{" "}
        </p>
        <p>
          15.2. Our Privacy Policy outlines our practices for collecting, using,
          and protecting personal data. By using our services, you consent to
          these practices. For details, please refer to our Privacy Policy on
          the Website.{" "}
        </p>
        <p>
          15.3. If you communicate with the Earth Coin LLC by e-mail, you should
          note that the security of e-mails is uncertain.{" "}
        </p>
        <p>
          15.4. By sending sensitive or confidential e-mail messages which are
          not encrypted, you accept the risks of such uncertainty and possible
          lack of confidentiality.{" "}
        </p>
      </section>
      <section>
        <p>16. Applicable law and Jurisdiction </p>
        <p>
          BY ACCESSING AND USING THE WEBSITE, YOU AGREE THAT THE LAWS OF St.
          Vincent and Grenadines, WITHOUT REGARD TO CONFLICTS OF LAWS PRINCIPLES
          THEREOF, WILL APPLY TO ALL MATTERS RELATING TO THE ACCESS AND USE OF
          THE WEBSITE. ANY DISPUTE SHALL BE SUBJECT TO THE EXCLUSIVE
          JURISDICTION OF THE COURTS OF St. Vincent and Grenadines.
        </p>
        <p>
          Thank you for being part of Earth Coin LLC's journey toward a
          solarpunk future. Together, let's seed the SolarPunk Paradigm.
        </p>
      </section>
    </div>
  );
}
