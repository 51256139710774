import { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./header.css";
import MenuIcon from "@mui/icons-material/Menu";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";
import { connectWallet } from "../../../Pages/Dapp/utils";

export default function Header() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const GAtrackingId = process.env.REACT_APP_GA_TRACK_ID;
  const HJtrackingId = process.env.REACT_APP_HJ_TRACK_ID;
  const wallet = useSelector((state) => state.auth.wallet);

  const getPath = () => {
    if (window.location.pathname === "/") {
      return "Home";
    } else {
      let value = window.location.pathname.split("/");
      return value[1];
    }
  };

  const [page, setPage] = useState(getPath());

  let menu = useRef(null);

  useEffect(() => {
    document.title = `${page} - Earth Coin - Solarpunk DAO`;
    window.scrollTo(0, 0);
  }, [page, navigate]);

  useEffect(() => {
    let value = window.location.pathname;
    value = value.split("/");
    setPage(value[1] ? value[1] : "Home");
  }, [navigate, page]);

  const closeOpenMenus = (e) => {
    if (menu.current && showMenu && !menu.current.contains(e.target)) {
      setShowMenu(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  useEffect(() => {
    const path = window.location.pathname + window.location.search;
    ReactGA.initialize(GAtrackingId);
    ReactGA.pageview(path);
    hotjar.initialize(HJtrackingId, 6);
    hotjar.stateChange(path);
  }, [GAtrackingId, navigate, page, HJtrackingId]);

  useEffect(() => {
    let navBar = document.querySelector(".navBar");
    let heading1 = document.querySelector(".nav-heading-1");
    let heading2 = document.querySelector(".nav-heading-2");
    let heading3 = document.querySelectorAll(".nav-style-inactive");
    let navBarContainer1 = document.querySelector(".navBar-section-1");
    let navBarContainer2 = document.querySelector(".navBar-section-3");

    let mobileMenuIcon = document.querySelector("#nav-menu-icon");
    let mobileMenuCloseIcon = document.querySelector("#nav-close-icon");

    if (
      window.location.pathname.length === 1 ||
      window.location.pathname === "/dapp" ||
      page === "Home"
    ) {
      heading1.style.color = "white";
      heading2.style.color = "white";

      if (mobileMenuIcon) {
        mobileMenuIcon.style.color = "white";
      }
      if (mobileMenuCloseIcon) {
        mobileMenuCloseIcon.style.color = "white";
      }

      for (let i = 0; i < heading3.length; i++) {
        heading3[i].style.color = "white";
      }

      navBar.style = `
        background-color: #0000004F;
        border-bottom:0.5px solid white !important;
        `;
      navBarContainer2.style = `border-left:0.5px solid white;`;
      navBarContainer1.style = `border-right:0.5px solid white;`;
    } else {
      heading1.style.color = "black";
      heading2.style.color = "black";
      for (let i = 0; i < heading3.length; i++) {
        heading3[i].style.color = "black";
      }
      navBar.style = `
      background-color: white;
      border-bottom:0.5px solid black;
      `;

      navBarContainer2.style = `border-left:0.5px solid black;`;
      navBarContainer1.style = `border-right:0.5px solid black;`;

      if (mobileMenuIcon) {
        mobileMenuIcon.style.color = "black";
      }
      if (mobileMenuCloseIcon) {
        mobileMenuCloseIcon.style.color = "black";
      }
    }
  }, [showMenu, navigate, page]);

  useEffect(() => {
    let mobileNavBar = document.querySelector(".navBar-section-2-mobile");
    if (
      window.location.pathname.length === 1 ||
      window.location.pathname === "/dapp"
    ) {
      if (mobileNavBar) {
        mobileNavBar.style = `background-color: rgba(0, 0, 0, 0.31);
      `;
      }
    } else {
      if (mobileNavBar) {
        mobileNavBar.style = `background-color: white;
    color:black;
    ;
    
    `;
      }
    }
  }, [showMenu, navigate]);

  // useEffect(()=>{
  //     if(!wallet)
  //     {
  //       connectWallet()
  //     }
  // },[])

  return (
    <nav>
      <div className='navBar'>
        <section className='navBar-section-1'>
          <NavLink
            className='nav-heading-1'
            onClick={() => setPage("Home")}
            to='/'
          >
            $Earth<span>Beta</span>
          </NavLink>
        </section>

        <div className='navBar-section-2'>
          <NavMenu className='navBar-section-2-web' setPage={setPage} />
        </div>

        <section className='navBar-section-3'>
          {page !== "dapp" ? (
            <NavLink
              className='nav-heading-2'
              onClick={() => setPage("dapp")}
              to='dapp'
            >
              ENTER DAPP
            </NavLink>
          ) : (
            <button
              onClick={() => connectWallet({ dispatch, setShowMenu, wallet })}
              className='nav-heading-2 walletButton'
            >
              {!wallet ? "Connect" : "Disconnect"} Wallet
            </button>
          )}

          <div className='mobile-nav-menu-btn'>
            {!showMenu ? (
              <span onClick={() => setShowMenu(true)} id='nav-menu-icon'>
                <MenuIcon />
              </span>
            ) : (
              <span id='nav-close-icon' onClick={() => setShowMenu(false)}>
                <HighlightOffIcon />
              </span>
            )}
          </div>
        </section>
      </div>

      {showMenu ? (
        <div className='navBar-mobile' ref={menu}>
          <NavMenu
            setShowMenu={setShowMenu}
            setPage={setPage}
            page={page}
            wallet={wallet}
            className='navBar-section-2-mobile'
          />
        </div>
      ) : (
        ""
      )}
    </nav>
  );
}

function NavMenu({ className, setShowMenu, setPage, page, wallet }) {
  const dispatch = useDispatch();
  const aboutUsLink = process.env.REACT_APP_ABOUT_US_URL;

  let headings = [
    { title: "Projects", link: "projects" },
    { title: "RAISON D'ETRE", link: "purpose" },
    { title: "thesis", link: "thesis" },
    { title: "tokenomics", link: "tokenomics" },
    { title: "faq", link: "faq" },
  ];
  const handleClick = (data) => {
    setShowMenu && setShowMenu(false);
    setPage(data);
  };

  return (
    <section className={className}>
      {headings.map(({ title, link }, i) => {
        return (
          <NavLink
            onClick={() => handleClick(link)}
            to={link}
            key={i}
            className={({ isActive }) =>
              isActive ? "nav-style-active" : "nav-style-inactive"
            }
          >
            {title}
          </NavLink>
        );
      })}

      <a
        href={aboutUsLink}
        className='nav-style-inactive'
        target='_blank'
        rel='noreferrer'
      >
        About Us
      </a>
      {page !== "dapp" ? (
        <NavLink
          onClick={() => handleClick("dapp")}
          id='nav-heading-2-mobile'
          className={({ isActive }) =>
            isActive ? "nav-style-active" : "nav-style-inactive"
          }
          to='dapp'
        >
          ENTER DAPP
        </NavLink>
      ) : (
        <button
          onClick={() => connectWallet({ dispatch, setShowMenu, wallet })}
          id='nav-heading-2-mobile'
          className={page === "dapp" && "nav-style-inactive walletButton"}
        >
          {!wallet ? "Connect" : "Disconnect"} Wallet
        </button>
      )}
    </section>
  );
}
