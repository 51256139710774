import "./teamList.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { NotifyUser } from "../../router";
import { Loading } from "../../Components/loading/loading";

import { SkeletonScreen } from "../../Components/Skeleton/skeleton";
import { id_config } from "../../id_config";
import { fetchData } from "../../helper";

export function TeamList() {
  const [team, setTeam] = useState("");
  const [imgLoading, setImgLoading] = useState(true);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const pageId = id_config.teamPage;
      const websiteId = id_config.website;

      const result = await fetchData({ pageId, websiteId });
      setTeam(result?.Teams);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onLoad = () => {
    setImgLoading(false);
  };

  return (
    <div className="dashboard-container">
      {team && Array.isArray(team) && team.length ? (
        <div className="teamList-container">
          {team.map(({ teamId, name, role, pic }) => {
            return (
              teamId && (
                <div key={teamId} className="ind-team-container">
                  <img
                    src={pic}
                    alt="team"
                    style={{ display: imgLoading ? "none" : "block" }}
                    onLoad={onLoad}
                  />
                  {imgLoading ? (
                    <SkeletonScreen
                      height="18rem"
                      width="18rem"
                      variant="rectangular"
                      id="skeleton-team"
                    />
                  ) : (
                    ""
                  )}
                  <div className="teamDetail-container">
                    {imgLoading ? (
                      <>
                        <SkeletonScreen
                          height="1rem"
                          width="18rem"
                          variant="rectangular"
                          id="skeleton-team"
                        />
                        <br />
                        <SkeletonScreen
                          height="1rem"
                          width="18rem"
                          variant="rectangular"
                          id="skeleton-team"
                        />
                        <br />

                        <SkeletonScreen
                          height="1rem"
                          width="18rem"
                          variant="rectangular"
                          id="skeleton-team"
                        />
                      </>
                    ) : (
                      <>
                        <label>Name</label>
                        <p>{name}</p>

                        <label>Role</label>
                        <p>{role}</p>
                      </>
                    )}
                  </div>
                </div>
              )
            );
          })}
        </div>
      ) : !team ? (
        <Loading />
      ) : (
        team && <p className="notFound-message-projectPage">Team Not Found</p>
      )}
    </div>
  );
}
