import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";

export function TabHeadings({label,disablePage,arr,setArr,value, setValue}) {

  const [heading,setHeading]=useState('')



  useEffect(()=>{

      setHeading([...label])

  },[label])

  const handleChange = (event, newValue) => {

    if(disablePage)
    {
      return
    }
    setValue(newValue);
    setArr(heading[newValue])
  };


  return (
    <div className="tab-container">
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="secondary tabs example"
          TabScrollButtonProps={{ style: { color: "rgba(38, 9, 94, 0.5)" } }}
          TabIndicatorProps={{
            style: { background: "#df663b" },
          }}
        >
          {heading && heading.map((data, i) => {
            return (
              <Tab
                key={i}
                id="tab-heading"
                style={{
                  color: arr === data ? "black" : "rgba(38, 9, 94, 0.5)",
                }}
                value={i}
                label={data} />
            );
          })}
        </Tabs>
      </Box>
    </div>
  );
}
