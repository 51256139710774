import { NotifyUser } from "../../router";
import { ethers } from "ethers";

const obj = {
  chainName: "Polygon Mainnet",
  chainId: "0x89",
  rpcUrls: ["https://polygon-rpc.com/"],
  blockExplorerUrls: ["https://polygonscan.com"],
  nativeCurrency: {
    symbol: "MATIC",
    decimals: 18,
  },
};
const addNetwork = async (obj) => {
  try {
    if (window?.ethereum !== undefined) {
      const provider = window.ethereum;
      let network = { ...obj };
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [network],
      });
      NotifyUser({
        type: "success",
        message: `Network Added`,
      });
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

const switchNetwork = async (obj) => {
  try {
    const provider = window.ethereum;
    const response = await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: obj.chainId }],
    });

    NotifyUser({
      type: "success",
      message: `Network switched to ${obj.chainName}`,
    });
    return true;
  } catch (error) {
    if (error.code === 4902) {
      return addNetwork(obj);
    }
    console.log(error);
  }
};
const checkNetwork = async () => {
  try {
    if (typeof window.ethereum !== "undefined") {
      const provider = window.ethereum;
      let chainId = await provider.chainId;

      if (chainId === obj.chainId) {
        return true;
      }
      return switchNetwork(obj);
    }
  } catch (error) {
    console.log(error);
  }
};
// const handleLogin = async () => {
//   if (typeof window.ethereum !== "undefined") {
//     let provider = new ethers.providers.Web3Provider(window.ethereum);
//     provider = await provider.getNetwork();
//     console.log(provider.chainId);
//     if (provider.chainId !== parseInt(process.env.REACT_APP_CHAINID)) {
//       NotifyUser({
//         type: "error",
//         message: "Change your network to " + process.env.REACT_APP_NETWORK,
//       });
//       return;
//     }
//     return true;
//   }
// };

export const connectWallet = async ({
  wallet,
  dispatch,
  setShowMenu,
  message,
}) => {
  try {
    if (!wallet) {
      const getNetworkResult = await checkNetwork();
      if (!getNetworkResult) {
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (accounts && accounts.length) {
        dispatch({
          type: "CONNECTWALLET",
          payload: accounts[0],
        });
        const balance = await provider.getBalance(accounts[0]);
        dispatch({
          type: "BALANCE",
          payload: ethers.utils.formatEther(balance),
        });
        NotifyUser({
          type: "success",
          message: message ? "Wallet Connected" : "Wallet Connected",
        });
      } else {
        NotifyUser({ type: "error", message: "No Wallet Found" });
      }
    } else {
      dispatch({ type: "DISCONNECT" });
      NotifyUser({ type: "success", message: "Wallet Disconnected" });
    }
    setShowMenu && setShowMenu(false);
  } catch (error) {
    console.error(error);
  }
};
