import axios from "axios";
import { id_config } from "./id_config";
const parseResponse = (responseJSON) => {
  let result = {};
  responseJSON.blocks.map((block) => {
    if (block.nested) {
      return;
    }
    if (Array.isArray(block.blockData)) {
      let arr = block.blockData.map((nestedObj) => {
        return Object.values(nestedObj).reduce((acc, obj) => {
          let blockData = obj.blockData;
          if (typeof blockData === "string") {
            let nested = responseJSON.blocks.find(
              (block) => block.id === blockData
            );
            let blockName = obj.fieldName;
            if (Array.isArray(nested.blockData)) {
              nested = nested.blockData.map((nestedObj) => {
                return Object.values(nestedObj).reduce((acc, { blockData }) => {
                  return { ...acc, ...blockData };
                }, {});
              });
              blockData = { [blockName]: nested };
            }
            if (typeof nested.blockData === "object") {
              nested = Object.values(nested.blockData).reduce(
                (acc, { blockData }) => {
                  return { ...acc, ...blockData };
                },
                {}
              );
              blockData = { [blockName]: nested };
            }
          }
          return { ...acc, ...blockData };
        }, {});
      });
      result[block.blockName] = arr;
      return;
    }
    if (block.blockType === "group") {
      result[block.blockName] = Object.values(block.blockData).reduce(
        (acc, { blockData }) => {
          return { ...acc, ...blockData };
        },
        {}
      );
      return;
    }

    result[block.blockName] = block.blockData[block.blockName];
  });
  return result;
};

export default parseResponse;

export const fetchData = async ({ websiteId, pageId }) => {
  try {
    const response = await axios({
      url: `${id_config.apiUrl}/api/page/${pageId}?websiteId=${websiteId}`,
      method: "GET",
      headers: {
        "x-api-key": id_config.apiKey,
      },
    });

    const result = parseResponse(response?.data);

    return result;
  } catch (error) {
    return;
  }
};
