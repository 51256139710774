import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useState } from 'react';
import './FAQ.css'


import { EditorState ,ContentState} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';

export function FAQ({ faq, home, heading, border }) {
  return (
    <div className="faq-container">
      {/* {home ? (
        <>
          {heading ? <p className="faq-heading">FAQS</p> : ""}
          {faq && faq.length
            ? faq.map(({ Question, Answer }, i) => {
                return (
                  <IndFAQ1 Question={Question} Answer={Answer} key={i} i={i} border={border} />
                );
              })
            : ""}
        </>
      ) : ( */}
        <>
          {heading ? <p className="faq-heading">FAQS</p> : ""}

          {faq && faq.length
            ? faq.map(({ Question, Answer }, i) => {
                return <IndFAQ2 Question={Question} Answer={Answer} key={i} border={border} i={i} />;
              })
            : ""}
        </>
      {/* )} */}
    </div>
  );
}




// function IndFAQ1({i,Answer,Question,border})
// {
//   let upArrow=<ArrowDropUpIcon id='showIcon'/>
//   let rightArrow=<ArrowRightIcon id='hideIcon'/>  
  
//   const [index,setIndex]=useState([])

//   const handleClick=(i)=>{
//     if(index===i)
//     {
//         setIndex('')
//         return
//     }

//     setIndex(i)

// }
  

//   return <div key={i} className={border ? 'faq-ind-container-v1' : 'faq-ind-container-v2' }>
//                 <div onClick={()=>handleClick(i)} className='faq-question-ind-container'>
//                   <p className={index!==i ?'faq-question' :'faq-question-show'}>{Question}</p>
//                   {index===i  ? upArrow : rightArrow}
//                 </div>

//                 {index===i ? <div className='faq-answer-ind-container'>
//                   {
                    
//                   Answer && // eslint-disable-next-line
//                     Answer.map(({ line,type,link },i) => { 
//                       if (line && typeof line === "string") 
//                       {
//                         return <p className='faq-answer' key={i}>{line}</p>;
//                       } 
//                       else if (line && typeof line === "object") 
//                       {

//                         if(type==='lower-alpha')
//                         {
//                           return line && line.map((text,i)=>{
//                             return (
//                                 <p className='faq-answer' key={i+1}>{(i+1 + 9).toString(36).toLowerCase()}. {text}</p>
//                               );
//                         })
//                         }
//                         else
//                         {
//                           return line && line.map((text,i)=>{
//                             return (
//                                 <p className='faq-answer' key={i+1}>{i+1}. {text}</p>
//                               );
//                         })
//                         }
//                       }
//                       else if(!line && link)
//                       {
//                         return <a className='faq-answer-link' href={link}>{link}</a>
//                       }
//                     })
//                     }
//                 </div>:''}
//               </div>
  
// }


function IndFAQ2({border,Question,Answer,i})
{

  let upArrow=<ArrowDropUpIcon id='showIcon'/>
  let rightArrow=<ArrowRightIcon id='hideIcon'/>  
  
  const [index,setIndex]=useState([])

  const handleClick=(i)=>{
    if(index===i)
    {
        setIndex('')
        return
    }

    setIndex(i)

}

const addElement=(value)=>{
  const contentBlock = htmlToDraft(value);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);
  return editorState   
}

    return <div key={i} className={border ? 'faq-ind-container-v1' : 'faq-ind-container-v2' }>
    <div
      onClick={() => handleClick(i)}
      className="faq-question-ind-container"
    >
      <p
        className={
          index !== i ? "faq-question" : "faq-question-show"
        }
      >
        {Question}
      </p>
      {index === i ? upArrow : rightArrow}
    </div>

    {index === i ? (
      <div className="faq-answer-ind-container">
        <Editor
          toolbarHidden
          editorState={addElement(Answer)}
          wrapperClassName="demo-wrapper"
          editorClassName="richText-display"
          readOnly
        />
        {/* <p className='faq-answer' key={i}>{addElement(Answer)}</p>; */}
      </div>):''
}
</div>

}