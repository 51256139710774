import Slider from "react-slick";

import "./slideshow.css";

export function SlideShow({ images, component, className, page }) {
  const settings = {
    fade: true,
    infinite: true,
    speed: 3000,
    autoplaySpeed:5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    lazyLoad: true,
  };

  return (
    <div className={className}>
      {component}

      {page !== "dapp" ? (
        <div className="slideshow-overlay"></div>
      ) : (
        <div className="slideshow-dapp-overlay"></div>
      )}

      <Slider {...settings}>
        {images &&
          images.map((data, i) => <img key={i} loading="lazy" src={data} alt="slide" />)}
      </Slider>
      <div></div>
    </div>
  );
}
