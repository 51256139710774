const Twitter = "/assets/twitter-black.png";
const Medium = "/assets/medium-black.png";
const Discord = "/assets/discord-black.png";
const GitBook = "/assets/git-logo-black.png";
const logo = "/assets/logo.png";
const climateSolutionImg1 = "/assets/climateSolutionImg-1.jpeg";
const climateSolutionImg2 = "/assets/climateSolutionImg-2.jpg";
const homePageGraph = "/assets/homePageGraph.png";
const homePageFlowChart = "/assets/homePageFlowChart.png";
const polygon = "/assets/polygon.png";
const Email = "/assets/Email.png";

export function getIcon(name) {
  switch (name) {
    case "Twitter":
      return Twitter;
    case "Medium":
      return Medium;
    case "Discord":
      return Discord;
    case "GitBook":
      return GitBook;
    case "logo":
      return logo;
    case "climateSolutionImg1":
      return climateSolutionImg1;
    case "climateSolutionImg2":
      return climateSolutionImg2;
    case "homePageGraph":
      return homePageGraph;
    case "homePageFlowChart":
      return homePageFlowChart;
    case "polygon":
      return polygon;
    case "Email":
      return Email;
    default:
      return GitBook;
  }
}
