import { useEffect, useState } from "react";
import { TwitterShareButton } from "react-share";
import { ethers } from "ethers";
import { NotifyUser } from "../../../router";
import Presale from "../abi/Presale.json";
import { StableCoin, EarthTreasury as EarthTreasuryJSON } from "../abi";
import Soulbound from "../abi/SoulBound.json";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { getBalance } from "../balance";

const stableCoinAddress = process.env.REACT_APP_STABLE_COIN_ADDRESS;
const presaleAddress = process.env.REACT_APP_PRESALE_ADDRESS;
const soulboundAddress = process.env.REACT_APP_SOULBOUND_CONTRACT_ADDRESS;
const EarthTreasuryAddress = process.env.REACT_APP_EARTHTREASURY_ADDRESS;
const twitter = "/assets/twitter.png";
const twitterContent = `I just purged my petro $$$ for $Earth to fund net zero infrastructure and seed the #solarpunk paradigm`;
const dexscreener =
  "https://api.dexscreener.com/latest/dex/pairs/polygon/0x40da612b7803892ed002e4f9effd746dc3cf4a5c";

export function Mintcomponent({ navigate }) {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const account = useSelector((state) => state.auth.wallet);
  const { dai: balance } = useSelector((state) => state.balance);

  const [result, setResult] = useState("");
  const [minted, setMinted] = useState(false);
  const [dexPrice, setDexPrice] = useState(0);
  const dispatch = useDispatch();
  const [mintValue, setMintValue] = useState({
    earth: 0,
    treasury: 0,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(dexscreener);
        const result = await response.json();
        setDexPrice(result?.pair?.priceUsd);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const isMinted = async () => {
    if (typeof window.ethereum !== undefined) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tt = await signer.getAddress();
        let contract = new ethers.Contract(
          soulboundAddress,
          Soulbound.abi,
          signer
        );

        return await contract.tokenMintedAddress(account);
      } catch (e) {
        console.log(e);
        NotifyUser({ type: "error", message: e.code });
      }
    }
  };

  const increaseAllowance = async () => {
    if (!input) {
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Amount = ethers.utils.parseUnits(input, "ether");

    const Balance = balance * Math.pow(10, 18);

    if (Number(Amount) > Balance) {
      NotifyUser({
        type: "error",
        message: "Amount exceeds balance",
      });
      return;
    }

    setLoading(true);

    const contract = new ethers.Contract(
      stableCoinAddress,
      StableCoin.abi,
      signer
    );
    try {
      console.log("Contract: ", contract);

      const allowance = await contract.increaseAllowance(
        Presale.address,
        Amount
      );
      getBalance();
      mint();
    } catch (error) {
      console.log(error);
      setLoading(false);
      NotifyUser({ type: "error", message: error.reason });
    }
  };

  const mint = async () => {
    if (typeof window.ethereum !== undefined) {
      const providers = new ethers.providers.Web3Provider(window.ethereum);
      const signer = providers.getSigner();
      const price = ethers.utils.parseUnits(input, "ether");
      const contract = new ethers.Contract(presaleAddress, Presale.abi, signer);
      try {
        const info = await contract.mint(
          price // changed
        );

        await info.wait();
        setLoading(false);
        getBalance();
        totalEarth();
        // treasuryFunction();
        NotifyUser({ type: "success", message: "Transaction Success" });
        setMinted(true);
      } catch (error) {
        console.log(error);
        setLoading(false);
        getBalance();
        setMinted(false);
        NotifyUser({ type: "error", message: error.reason });
      }
    }
  };

  const handleSubmit = async () => {
    let minted = await isMinted();

    if (!minted) {
      NotifyUser({ type: "error", message: "Soulbound Token missing" });
      return;
    }

    return increaseAllowance();
  };

  const handleChange = ({ target: { name, value } }) => {
    let validatedValue = value.replace("e", "");

    if (/^[+-]?\d+(\.\d*)?$/.test(validatedValue) || value === "") {
      setInput(validatedValue);
    }
  };

  const formatToTwoDecimalPlaces = (value) => {
    return Number(value)?.toFixed(2);
  };

  useEffect(() => {
    if (input && mintValue.earth) {
      const value = input / (mintValue.earth / Math.pow(10, 18));
      setResult(value);
      console.log({
        input,
        output: value,
        earth: mintValue.earth / Math.pow(10, 18),
        formatted: formatToTwoDecimalPlaces(value),
      });
    } else {
      setResult(0);
    }
  }, [mintValue.earth, input]);

  const totalEarth = async () => {
    if (typeof window.ethereum !== undefined) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const treasuryContract = new ethers.Contract(
        EarthTreasuryAddress,
        EarthTreasuryJSON.abi,
        signer
      );
      const presaleContract = new ethers.Contract(
        presaleAddress,
        Presale.abi,
        signer
      );
      try {
        const info = await treasuryContract.intrinsicValueRatio();
        const ratio =
          info.earth.toString() !== "0"
            ? parseFloat(info.stablec.toString() / Math.pow(10, 18)) /
              parseFloat(info.earth.toString() / Math.pow(10, 18))
            : 0;

        const mintMultiple = await presaleContract.mintMultiple();
        let t = mintMultiple.toString() / 10;
        console.log(mintMultiple.toString());
        setMintValue({
          earth: Math.round(100 * (parseFloat(ratio) * parseFloat(t))) / 100,
          treasury: info.stablec.toString() / Math.pow(10, 18),
        });
        console.log(
          Math.round(100 * (parseFloat(ratio) * parseFloat(t))) / 100,
          info.stablec.toString() / Math.pow(10, 18)
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getBalance();
    totalEarth();

    // const intervalId = setInterval(() => {
    //   getBalance();
    // }, 5000);

    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);

  return !minted ? (
    <>
      <div className='mint-container'>
        <p className='mint-heading'>
          $Earth can only be minted by purging $Dai here
        </p>
        <p className='mint-description'>
          You will need a{" "}
          <button className='tab-navigate-btn' onClick={navigate}>
            $Earth Steward SBT
          </button>{" "}
          to be eligible to mint
        </p>
        <div className='mint-price-container'>
          <div className='earth-price-container'>
            <p className='price-amount'>$10.00</p>
            <p className='price-label'>$Earth price</p>
          </div>
          <div className='earth-price-container'>
            <p className='price-amount'>${dexPrice}</p>
            <p className='price-label'>$Earth Price on Dex</p>
          </div>
          <div className='earth-price-container'>
            <p className='price-amount'>
              ${formatToTwoDecimalPlaces(mintValue.treasury)}
            </p>
            <p className='price-label'>Treasury Size</p>
          </div>
        </div>
      </div>
      <div className='mint-amount-container'>
        <div className='dai-amount-container'>
          <p className='input-label'>Enter $Dai amount</p>
          <div className='amount-inputfield-container'>
            <input
              className='amount-input dai-amount-feild'
              onChange={handleChange}
              value={input}
              type='number'
              name='number'
            />
            <p className='input-tag'>$Dai</p>
          </div>
        </div>
        <p className='equalto-symbol'>=</p>
        <div className='dai-amount-container'>
          <p className='input-label'>You will receive</p>
          <div className='amount-inputfield-container'>
            <p className='amount-result'>{formatToTwoDecimalPlaces(result)}</p>
            <p className='input-tag'>$Earth</p>
          </div>
        </div>
        <button onClick={handleSubmit} className='mint-button'>
          MINT
          {loading ? <LoadingOutlined /> : null}
        </button>
      </div>
    </>
  ) : (
    <div className='dapp-trade-container'>
      <p className='twitter-share-msg'>
        Congrats on purging your petro $$$ for $Earth
      </p>
      <TwitterShareButton url={twitterContent} style={{ margin: "auto" }}>
        <button className='twitter-share-btn'>
          <img className='twitter-share-icon' src={twitter} alt='twitter' />
          Share via Twitter
        </button>
      </TwitterShareButton>
    </div>
  );
}
