import { FAQ } from "../../Components/FAQ/FAQ";
import "./faq.css";
import { useState, useEffect } from "react";
import { NotifyUser } from "../../router";
import { Loading } from "../../Components/loading/loading";
import { fetchData } from "../../helper";
import { id_config } from "../../id_config";

export default function FAQPage() {
  const [faq, setFaq] = useState("");

  useEffect(() => {
    const getFaq = async () => {
      try {
        const pageId = id_config.faqsPage;
        const websiteId = id_config.website;

        const result = await fetchData({ pageId, websiteId });
        setFaq(result?.Faq);
      } catch (error) {
        return;
      }
    };
    getFaq();
  }, []);

  return (
    <section className="faq-page">
      {faq && faq.length ? (
        <FAQ home heading faq={faq} border />
      ) : !faq ? (
        <Loading />
      ) : (
        faq && <p className="notFound-message">FAQ Not Found</p>
      )}
    </section>
  );
}
