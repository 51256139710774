import { useEffect, useRef, useState } from "react";
import { Contract, providers, utils } from "ethers";
import Web3Modal from "web3modal";
import {
  UID_CONTRACT_ADDRESS,
  abi,
  TOKEN_CONTRACT_ADDRESS,
  abiToken,
} from "./constants";
import { useSelector } from "react-redux";
import Persona from "persona";
import { NotifyUser } from "../../router";
import { CircularProgress } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { ethers } from "ethers";

import Soulbound from "./abi/SoulBound.json";
import { id_config } from "../../id_config";

const soulboundAddress = process.env.REACT_APP_SOULBOUND_CONTRACT_ADDRESS;

export function SBT() {
  const [signed, setSigned] = useState(false);
  const [loading, setLoading] = useState({
    verifyLoading: false,
    signLoading: false,
    mintLoading: false,
  });

  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const walletAddressRef = useRef();
  const web3ModalRef = useRef();
  const signatureRef = useRef();
  const account = useSelector((state) => state.auth.wallet);
  const [pageStatus, setPageStatus] = useState({
    verifyStatus: "",
    verify: false,
    whiteListed: false,
    minted: false,
  });
  console.log(pageStatus);
  const getProviderOrSigner = async (needSigner = false) => {
    // Connect to Metamask
    // Since we store `web3Modal` as a reference, we need to access the `current` value to get access to the underlying object
    try {
      const provider = await web3ModalRef.current.connect();
      const web3Provider = new providers.Web3Provider(provider);

      // If user is not connected to the Goerli network, let them know and throw an error
      const { chainId } = await web3Provider.getNetwork();
      console.log(chainId);
      if (chainId !== 80001) {
        window.alert("Change the network to Polygon");
        throw new Error("Change network to Polygon");
      }

      const signer = web3Provider.getSigner();
      walletAddressRef.current = await signer.getAddress();
      if (needSigner) {
        return signer;
      }
      return web3Provider;
    } catch (e) {
      console.log("------------------------------------");
      console.log(e);
      console.log("------------------------------------");
    }
  };

  async function signData() {
    if (signatureRef.current === undefined) {
      setLoading((loading) => ({ ...loading, signLoading: true }));
      try {
        const signer = await getProviderOrSigner(true);
        const message = utils.solidityKeccak256(
          ["string"],
          ["You confirm you are the owner of this wallet"]
        );

        // Sign the data

        const signature = await signer.signMessage(
          "You confirm you are the owner of this wallet"
        );
        signatureRef.current = signature;
        console.log("------------------------------------");
        console.log("Signature", signatureRef.current);
        setSigned(true);

        console.log("------------------------------------");
      } catch (e) {
        NotifyUser({
          type: "error",
          message: e.code,
        });
        setLoading((loading) => ({ ...loading, signLoading: false }));

        console.log(e.code);
      }
    }
  }

  const isVerified = async () => {
    setLoading((loading) => ({ ...loading, verifyLoading: true }));
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-api-key": id_config.apiKey,
      },
      body: JSON.stringify({ method: "get" }),
    };
    fetch(`${id_config.apiUrl}/api/cors/${id_config.corsId}`, options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data, "JJJJJJ");
        for (let i = 0; i < response.data.length; i++) {
          const refId = response.data[i].attributes["reference-id"];
          const status = response.data[i].attributes["status"];

          console.log(refId, status);
          if (refId?.toLowerCase() === account?.toLowerCase()) {
            if (status === "approved") {
              setPageStatus((pageStatus) => ({
                ...pageStatus,
                verifyStatus: status,
                verify: true,
              }));
            } else {
              setPageStatus((pageStatus) => ({
                ...pageStatus,
                verifyStatus: status,
                verify: false,
              }));
            }
            break;
          }
        }
        setLoading((loading) => ({ ...loading, verifyLoading: false }));
      })
      .catch((err) => {
        setLoading((loading) => ({ ...loading, verifyLoading: false }));

        console.error(err);
      });
  };

  // const hasToken = async () => {
  //   try {
  //     const signer = await getProviderOrSigner(true);
  //     const contract = new Contract(UID_CONTRACT_ADDRESS, abi, signer);
  //     const flag = await contract.hasToken();
  //     setToken(flag);
  //     console.log(flag, "===Token");
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // const mintToken = async () => {
  //   try {
  //     if (!pageStatus.verify) {
  //       NotifyUser({
  //         type: "error",
  //         message: "Please Complete your KYC to mint this SBT",
  //       });
  //       return;
  //     }
  //     const signer = await getProviderOrSigner(true);
  //     const contract = new Contract(UID_CONTRACT_ADDRESS, abi, signer);
  //     const tx = await contract.safeMint();
  //     await tx.wait();
  //   } catch (e) {
  //     NotifyUser({
  //       type: "error",
  //       message: "Error",
  //     });
  //     console.error(e);
  //   }
  // };

  // const whitelistAddress = async () => {
  //   try {
  //     const signer = await getProviderOrSigner(true);
  //     const contract = new Contract(UID_CONTRACT_ADDRESS, abi, signer);
  //     const tx = await contract.addToWhiteList(address);
  //     await tx.wait();
  //     window.alert("Address Added to whitelist");
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const safeMint = async (e) => {
    e.preventDefault();

    if (!pageStatus.verify) {
      NotifyUser({
        type: "error",
        message: "Please Complete your KYC to mint this SBT",
      });
      return;
    }
    if (typeof window.ethereum !== undefined) {
      setLoading((loading) => ({ ...loading, mintLoading: true }));

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tt = await signer.getAddress();
        let contract = new ethers.Contract(
          soulboundAddress,
          Soulbound.abi,
          signer
        );

        let transaction = await contract.safeMint();
        await transaction.wait();

        isMinted();
        NotifyUser({ type: "success", message: "Successfully Minted!" });
        setLoading((loading) => ({ ...loading, mintLoading: false }));
      } catch (e) {
        console.log(e);
        NotifyUser({ type: "error", message: e.code });
        setLoading((loading) => ({ ...loading, mintLoading: false }));
      }
    }
  };

  const handleUnstake = () => {
    console.log(input);
    for (let i in input) {
      if (!input[i]) {
        NotifyUser({ type: "error", message: `${i} is required` });
        return;
      }
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const isValid = emailRegex.test(input.email);

    if (!isValid) {
      NotifyUser({ type: "error", message: `Invalid email` });

      return;
    }

    signData();
  };

  useEffect(() => {
    web3ModalRef.current = new Web3Modal({
      network: "goerli",
      providerOptions: {},
      disableInjectedProvider: false,
    });

    if (account) {
      isVerified();
      isWhitelisted();
      isMinted();
    }
  }, [account]);

  if (loading.verifyLoading) {
    return (
      <div className='dapp-loading-container'>
        <CircularProgress id='loading' />
      </div>
    );
  }

  const isMinted = async () => {
    if (typeof window.ethereum !== undefined) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tt = await signer.getAddress();
        let contract = new ethers.Contract(
          soulboundAddress,
          Soulbound.abi,
          signer
        );

        let result = await contract.tokenMintedAddress(account);
        setPageStatus((status) => ({ ...status, minted: result }));
        console.log({ minted: result });
      } catch (e) {
        console.log(e);
        NotifyUser({ type: "error", message: e.code });
      }
    }
  };

  const isWhitelisted = async () => {
    if (typeof window.ethereum !== undefined) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tt = await signer.getAddress();
        let contract = new ethers.Contract(
          soulboundAddress,
          Soulbound.abi,
          signer
        );

        let result = await contract.whitelistedAddresses(account);
        setPageStatus((status) => ({ ...status, whiteListed: result }));
        console.log({ whiteListed: result });
        result && isMinted();
      } catch (e) {
        console.log(e);
        NotifyUser({ type: "error", message: e.code });
      }
    }
  };

  const handlePersonaCompletion = () => {
    setSigned(false);
    isVerified();
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const kycDisplay = () => {
    const { verifyStatus, whiteListed, minted } = pageStatus;

    if (minted) {
      return (
        <div className='sbt-mint-message-container '>
          <p className='sbt-message'>Congrats, you can now mint $Earth</p>
        </div>
      );
    }

    if (verifyStatus && verifyStatus !== "created") {
      return (
        <div className='sbt-mint-message-container '>
          <p className='sbt-kyc-description'>
            Once your KYC documents have been approved, your wallet will be
            whitelisted to mint this SBT
          </p>
          <br />
          <button
            onClick={safeMint}
            disabled={!whiteListed}
            className={
              whiteListed ? "sbt-unstake-button" : "sbt-unstake-button-disabled"
            }
          >
            Mint SBT {loading.mintLoading ? <LoadingOutlined /> : null}
          </button>
        </div>
      );
    }
    // else if (!whiteListed && verifyStatus === "approved") {
    //   return (
    //     <div className='sbt-mint-message-container'>
    //       <p className='sbt-message'>KYC Verified Successfully</p>
    //       <p className='sbt-description'>
    //         Your <span>&quot;{account}&quot;</span> wallet is not whitelisted.
    //         Once it is whitelisted, you can mint your SBT.
    //       </p>
    //     </div>
    //   );
    // } else if (whiteListed && !minted) {
    //   return (
    //     <div className='sbt-mint-message-container'>
    //       <p className='sbt-message'>
    //         Your <span>&quot;{account}&quot;</span> wallet is whitelisted,you
    //         can mint your SBT{" "}
    //       </p>
    //     </div>
    //   );
    // }
    else if (minted) {
      return (
        <div className='sbt-mint-message-container'>
          <p className='sbt-message'>Verified and Soulbound Token Minted</p>
        </div>
      );
    }

    return (
      <div className='sbt-kyc-text-container'>
        <p className='sbt-kyc-text'>
          {" "}
          Complete your KYC to mint this Sould Bound NFT which gives you access
          to mint $Earth
        </p>
        <div className='sbt-email-field-conatiainer'>
          {/* <label>First Name</label> */}
          <input
            type='text'
            placeholder='first name'
            autoFocus={true}
            name='firstName'
            value={input.firstName}
            onChange={handleChange}
          />
        </div>
        <div className='sbt-email-field-conatiainer'>
          {/* <label>Last Name</label> */}
          <input
            type='text'
            placeholder='last name'
            name='lastName'
            value={input.lastName}
            onChange={handleChange}
          />
        </div>
        <div className='sbt-email-field-conatiainer'>
          {/* <label>Email</label> */}
          <input
            type='text'
            placeholder='email'
            name='email'
            value={input.email}
            onChange={handleChange}
          />
        </div>
        <button onClick={handleUnstake} className='sbt-unstake-button'>
          Get KYC {loading.signLoading ? <LoadingOutlined /> : null}
        </button>
        <br />
      </div>
    );
  };

  console.log(pageStatus);

  return (
    <div>
      <div className='sbt-content-container'>
        {kycDisplay()}

        {!pageStatus.verified && !pageStatus.verifyStatus && signed ? (
          <Verifier
            address={walletAddressRef}
            setLoading={setLoading}
            input={input}
            handlePersonaCompletion={handlePersonaCompletion}
          />
        ) : null}
      </div>
      {/* {pageStatus.whiteListed && !pageStatus.minted ? (
        <div className='dapp-trade-btn-container' onClick={safeMint}>
          <p> Mint {loading.mintLoading ? <LoadingOutlined /> : null}</p>
        </div>
      ) : null} */}
    </div>
  );
}

const Verifier = ({ address, setLoading, input, handlePersonaCompletion }) => {
  const [referenceId, setReferenceId] = useState("");

  return (
    <div className='persona-auth-overlay'>
      {
        !referenceId ? (
          <div className='persona-popup'>
            <Persona.Inquiry
              className='persona'
              onReady={() =>
                setLoading((loading) => ({ ...loading, signLoading: false }))
              }
              templateId='itmpl_LeEZ9mquzr861WGerXR4yshU'
              environmentId='env_oHeLwXnBxzNS7qsLHeEtkBqv'
              referenceId={address.current}
              fields={{
                nameFirst: input?.firstName,
                nameLast: input?.lastName,
                emailAddress: input?.email,
              }}
              onLoad={() => {
                console.log("Loaded inline");
              }}
              onComplete={({ inquiryId, status, fields }) => {
                setReferenceId(inquiryId);
                handlePersonaCompletion(inquiryId);

                // Inquiry completed. Optionally tell your server about it.
                console.log(`Sending finished inquiry ${inquiryId} to backend`);
              }}
            />
          </div>
        ) : null
        //   <div className='sbt-email-popup'>
        //     <h2 className='sbt-email-heading'>KYC Submitted Successfully!!</h2>
        //     <p className='sbt-email-message'>
        //       Depending upon the documents submitted your KYC will be verified in
        //       1-24hrs, please leave your email or twitter handle for us to contact
        //       you once its successfully verified.If not come back in 24hrs and
        //       mint your SBT if your documents have been successfully verified.
        //     </p>

        //     <form className='sbt-email-form'>
        //       <div className='sbt-email-field-conatiainer'>
        //         <input
        //           type='text'
        //           placeholder='Email'
        //           autoFocus={true}
        //           onChange={(e) => setEmail(e.target.value)}
        //         />
        //       </div>
        //       <button type='button' disabled={!email}>
        //         Submit
        //       </button>
        //     </form>
        //   </div>
      }
    </div>
  );
};
