import { getIcon } from "../../../Icon";
import "./climateSolution.css";

export function ClimateSolution() {
  return (
    <div className="climateSolution-container">
      <p className="climateSolution-heading-1">$EARTH</p>
      {/* <div className="climateSolution-divider">
        <p></p>
      </div> */}
      <p className="climateSolution-heading-2-web">
        Currency for the solarpunk paradigm,
        <br /> backed by climate solutions.
      </p>
      <p className="climateSolution-heading-2-mobile">
        Currency for the solarpunk paradigm,
        backed by climate solutions.
      </p>

      <div className="climateSolution-content-container">
        <div className="climateSolution-content-1">
          <img src={getIcon("climateSolutionImg1")} alt="solution" />
          <p className="climateSolution-content-description">
            A wise man once said -{" "}
            <span>
              One cannot create a solution from the same frequency that created
              the problem.    
            </span>         
            {/* <br /> */}
                   It is our belief that Solarpunk provides a holistic solution to the
            climate/ecological crisis created by neoliberal capitalism.
            Solarpunk envisages a radically optimistic view of the future where
            nature and tech seamlessly integrate to{" "}
            <span>
              maximize well being, regeneration, and spirituality while
              minimizing inequality.
            </span>
          </p>
        </div>

        <div className="climateSolution-content-2">
          <p className="climateSolution-content-description">
            A solarpunk world is only possible once we have successfully
            addressed anthropogenic climate change. So in order to fulfill our
            vision of building solarpunk societies we are currently on a mission
            to build the necessary infrastructure for an absolute zero emission
            world leveraging web3 tools because climate change is no longer a
            problem of technology or economic feasibility but of
            <span> capital and coordination.</span>
          </p>
          <img src={getIcon("climateSolutionImg2")} alt="solution" />
        </div>
      </div>
    </div>
  );
}
