import { getIcon } from "../../../Icon";
import "./graph.css";
import { Video } from "../../../Components/Video/video";

export function Graph() 
{

    
  let video1Content = (
    <div className="homePage-video1-description">
      <p>
        Building a monetary system backed by <span><br/> core utility</span>{" "}
        providing climate solutions.
      </p>
      <div className="video-description-divider"></div>
      <p>
        Designed to be the <span>medium of exchange</span> for our solarpunk
        future.
      </p>
    </div>
  );


  let  ClimatePolicyInitiative='https://www.climatepolicyinitiative.org/publication/global-landscape-of-climate-finance-2021/'

  return (
    <div className="homePage-graph-container">
      <p className="homePage-graph-heading">Climate Finance gap</p>
      <div className="homePage-graph-img-container">
        <img src={getIcon("homePageGraph")} alt="graph" />
        <a href={ClimatePolicyInitiative} target='_blank' rel="noreferrer" >Source: Climate Policy Initiative</a>
      </div>

      <div className="flowChart-container">
        <div className="flowChart-description-container">
          <p className="description">
            $Earth will act as a borderless, capital efficient funnel <br/> to{" "}
            <span> incentivize, mobilize &amp; allocate </span> capital<br/> towards
            climate solutions.
          </p>
        </div>
        <br/>

        <div className="flowChart-video-container">
        <img
          src={getIcon("homePageFlowChart")}
          className="homePageFlowChart"
          alt="flowchart"
        />
        <div className="homePage-video1-container">
          <div className="homePage-video1-overlay"></div>

          <Video
            src="/assets/homePage-video-1.mp4"
            content={video1Content}
            iframe={false}
            video={true}
            className="homePage-video1"
            poster='/assets/video1-placeholder.jpg'
          />

    
        </div>
        </div>

      </div>


 


    </div>
  );
}





