import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import "./skeleton.css";

export function SkeletonScreen({ height, width, variant, id }) 
{
  return (
    <Stack spacing={1}>
      <Skeleton
        variant={variant}
        animation="pulse"
        width={width ? width : ""}
        height={height ? height : ""}
        id={id}
      />
    </Stack>
  );
}
