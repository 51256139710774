import { authReducer } from "./Auth/AuthReducer";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { balanceReducer } from "./Balance/reducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["balance"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  balance: balanceReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? []
    : composeEnhancers(applyMiddleware());

const store = createStore(persistedReducer, middleware);
const persistor = persistStore(store);

export { store, persistor };
