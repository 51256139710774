import { Video } from "../../Components/Video/video";
import "./about.css";
import { useNavigate } from "react-router-dom";

export default function About() {
  let content = (
    <div className="about-container">
      <p>PURPOSE</p>
    </div>
  );

  let navigate=useNavigate()

  return (
    <div className="aboutPage">
      <div className="about-video-container">
        <Video
          src="/assets/about-video.mp4"
          iframe={false}
          video={true}
          content={content}
          className="about-video"
          poster='/assets/about-placeholder.jpg'
        />
      </div>
      {/* <Content/> */}

      <div className="content">
        <h3 className="content-heading-1">Context before currency, <span>Planet before profits.</span></h3>
        <p>
          A cosmic satellite observing Sapiens on Earth would certainly admit we
          have progressed leaps and bounds in eliminating existential threats
          and pushing the envelope of our consciousness. From developing
          language to communicate mythical tales of imagined orders now known as
          'culture' to establishing settled socieities because of agriculture ,
          we have propelled ourselves to the top of the food chain like a rocket
          on a geological time scale. Our neocortical abilities have enabled us
          to take complex actions in large scale cooperation frameworks
          (religion, economy and politics), decode phenomenons around us and
          harness the power of resources due to which we have largely bypassed
          our biological limitations and succeeded in hosting over 7 billion of
          us on earth. The sapien design coupled with earth's resources and its
          presence in the goldilocks zone has created a realm of endless
          possibilities where we are only{" "}
          <span className="org-color-highlight"> limited by our imagination and boundaries of science.</span>
        </p>

          <div className='about-img-container'>
            <img src='/assets/about-img-1.jpg' alt="description-img"/>
            <img src='/assets/about-img-2.jpg' alt="description-img"/>
          </div>

        <h3 className="content-heading-2">
          The recently experienced exponential advances although are not{" "}
          <span className="org-color-highlight2">decoupled</span> from its{" "}
          <span className="org-color-highlight2">tradeoffs</span> -
        </h3>
        <div className="keypoints-container">
          <p>
            · We have sacrificed our connection to the natural world, perceiving
            it as something that can be domesticated by human will (fairly
            succeeded at that too).
          </p>

          <p>
            · Accepted incessant planning for the future as a given since the
            advent of the agricultural revolution.
          </p>

          <p>
            · Assumed invincibility of our species which has been in existence
            for over a couple million years on this earth. The same earth which
            has had 5 mass extinctions from 450 million yrs back till date that
            has wiped out between 75-90 percent of species every time. 4 of
            those have been climate change induced.
          </p>
        </div>
        
        <div className="about-quote-img-container">
        <img  className='about-quote-img'  src='/assets/dalai-lama.jpg' alt="description-img"/>
        </div>

        <p>
          The dissonance amongst humanity in recognizing the inter connectedness
          of life and that{" "}
          <span className="blk-color-highlight"> we are all simply matter processing information differently</span>{" "}
          (whether we got the better bargain in this only time can tell). What
          began with our departure from the hunter/gatherer lifestyle has
          meandered its way to the pervasion of neoliberal thought that is
          assumed for 'reality' in today's time. The quote that says it is
          easier to imagine the end of world than capitalism has never been more
          true. It is essential we understand that{" "}
          <span className="blk-color-highlight">
            our current superiority is small parts our effort and in large parts serendipity.
          </span>{" "}
          <span className="org-color-highlight">
            Our perpetual superiority will be large parts effort and small parts luck.
          </span>{" "}
          Hence tread carefully we must as we are yet to find sign of
          intelligent life absolutely anywhere across the enormously chaotic
          universe we exist in. Even if we do Life as we know it is a planetary
          phenomenon, we will never feel completely at home anywhere else in the
          universe.
        </p>

        <h3 className="content-heading-2">
          The aforementioned tradeoffs or shifts in perception have now come seeking reparations.
          The Anthropocene era characterized by -
        </h3>

        <div className="keypoints-container">
          <p>
            · Our unrestrained extraction of the decayed plant and animal matter
            converted to fuel over hundreds of millions of years being exposed
            to the atmosphere in a matter of mere centuries.
          </p>

          <p>
            · Our unhindered destruction of thriving natural ecosystems for raw
            materials, grazing and agriculture.
          </p>

          <p>
            · Our infusion of deadly chemicals into the soil, air and water for
            intensifying agricultural output.
          </p>

          <p>· Our toxic use and throw consumerism for exponential growth.</p>
        </div>

        <img  className='about-cover-img1'  src='/assets/about-img-3.jpg' alt="description-img"/>
        

        <div className="about-desc-img-container">
          <div>
            <p>
              The massive rise in our standards of living at nature's expense
              has now come claiming the debt in the face of climate change.{" "}
              <span className="blk-color-highlight">
                A debt for which we are unable to come up the necessary accounting
                standards to restructure it as we have done so for our economies.
              </span>{" "}
              A debt that is topped with air pollution, land degradation,
              plastification of oceans, biodiversity loss, mounting landfills
              and freshwater depletion.
            </p>
            <p>
              The onslaught of major environmental issues is a reflection of the
              deep systemic fault lines that exist within our ideology of
              sustaining life currently and it is nature's way of returning the
              favor we have so dearly bestowed upon it in recent times.
            </p>
          </div>
            
          <div>
          <img src='/assets/about-img-4.jpg' alt="description-img"/>
          </div>
        </div>

        <p>
          Climate change is more of an umbrella term to describe the various
          repercussions that a warming earth can have. From raging wildfires,
          rising sea levels, floods and droughts, intensified cyclones,
          widespread hunger, unbearable temperatures to unbreathable air. These
          elements of chaos are fully capable of making the COVID pandemic feel
          like a ripple in a tsunami. Waves of which we have already begun
          witnessing and these consequences are still simply what is within our
          scope of comprehension,{" "}
          <span className="blk-color-highlight">
            unforeseen feedback loops are the icing on the proverbial multi
            layered climate change cake.
          </span>{" "}
        </p>

        <div className="about-desc-img-container">
          <div className="about-img-container-1">
          <img src='/assets/about-img-5.png' alt="description-img"/>
          </div>
          <div>
            <p>
              Also before we begin to rationalize climate changes on geological
              timelines it behooves us to keep in mind that there weren't over 7
              going to 9 billion humans breathing on this planet to face its
              aftermath in the past. While the fruits of development have been
              unevenly distributed, perils of climate change too are unevenly
              distributed.
            </p>
            <p>
              It is now a commonly accepted premise that section of humanity
              that is largely responsible for the predicament at hand is also
              better equipped to deal with the problem while the lower rungs of
              society will be left gasping as usual. Un/Fortunately cyclones,
              fires, viruses and floods don't comprehend rich and poor
              neighborhoods, all are equally eligible for the trials and
              tribulations served by the natural world.
            </p>
          </div>
        </div>

        <p>
          Since we all (some more than others) have our un/conscious
          contributions towards the creation of this climate disaster we need to
          address the climate disaster also collectively (some more than
          others). Surely in retrospect we don't want to realize that all the
          while we were{" "}
          <span className="blk-color-highlight">focused on aiming for the sky that we were actually digging our graves.</span>{" "}
          Earth has
          provided more than our wildest dreams could ask for it continues to do
          so but progress of humanity on earth with deeper strides in
          technological innovation, higher standards of living and becoming a
          multi planetary species is truly only possible only when the
          environment is conducive towards it. Not when every action undertaken
          to date by 21st century humans is in direct detriment of the planet we
          inhabit.
        </p>
        <p>
          Although we see governments and corporates try to take 'bold' action
          to ward the worst of climate change, its like Einstein said - it is
          not possible to solve a problem from the same level of frequency that
          created it. Just like a web3 world cannot be created with a web2
          business model, the Solarpunk paradigm cannot be established with the
          current socio-economic framework.
        </p>

        <p>
          It is in the spirit of addressing the concerns holistically that
          SolarpunkDao has conceptualized{" "}
          <span className="org-color-highlight">$Earth.</span> Recognizing that
          the problems have come from the separation of environment and economy
          and that for Sapiens to thrive a{" "}
          <span className="org-color-highlight">
            synergistic relationship between ecology and economy is essential.
          </span>{" "}
          Given the systemic nature of the climate change problem, addressing
          them also addresses several other problems in our society.
        </p>

        <h3 className="blk-color-highlight">
          While the economy is futile without ecology, ecology is absolutely
          fine without the economy.<span className="org-color-highlight"> Creating resonance between the two is the only way forward.</span>
          
        </h3>

        <h3 className="about-center-text" onClick={()=>navigate('/dapp')}>Mint $Earth to ...</h3>   

        <div className="about-img-container-2">
          <img src='/assets/about-img-6.jpg' alt="description-img"  />
          <img src='/assets/about-img-7.webp' alt="description-img"  />
        </div>

      </div>
      {/* <People people={JSON.stringify(people)}/> */}

    </div>
  );
}
