import { Outlet } from "react-router-dom";
import Footer from "./Footer/footer";
import Header from "./Header/header";

export default function BasicLayout()
{
    return <div className="layout">
        <Header/>
        <Outlet/>
        <Footer/>
    </div>
}