import { ethers } from "ethers";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { NotifyUser } from "../../router";
import ClaimJson from "./abi/TokenAirdrop.json";
import { useSelector } from "react-redux";
import { TwitterShareButton } from "react-share";
import { CircularProgress } from "@mui/material";

const twitter = "/assets/twitter.png";

const presentIcon = "/assets/logo1.jpg";

const claimAddress = process.env.REACT_APP_CLAIM_CONTRACT_ADDRESS;

export function Claim({ navigate }) {
  const [loading, setLoading] = useState({
    whitelist: false,
    claim: false,
  });
  const account = useSelector((state) => state.auth.wallet);
  const [progress, setProgress] = useState({
    whitelisted: false,
    claim: false,
  });

  const [claimed, setClaimed] = useState(false);

  const isWhitelisted = async () => {
    if (typeof window.ethereum !== undefined) {
      try {
        setLoading((obj) => ({ ...obj, whitelist: true }));
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        let contract = new ethers.Contract(claimAddress, ClaimJson.abi, signer);
        let result = await contract.isWhitelisted(account);
        console.log({ result });
        setProgress((progress) => ({ ...progress, whitelisted: result }));
        setLoading((obj) => ({ ...obj, whitelist: false }));
      } catch (e) {
        console.log(e);
        setLoading((obj) => ({ ...obj, whitelist: false }));
      }
    }
  };

  useEffect(() => {
    isWhitelisted();
  }, []);

  const claimFn = async () => {
    if (typeof window.ethereum !== undefined) {
      const providers = new ethers.providers.Web3Provider(window.ethereum);
      const signer = providers.getSigner();
      const contract = new ethers.Contract(claimAddress, ClaimJson.abi, signer);
      setLoading((obj) => ({ ...obj, claim: true }));

      try {
        const info = await contract.claimTokens();

        await info.wait();
        setLoading((obj) => ({ ...obj, claim: false }));

        setProgress((progress) => ({ ...progress, claim: true }));
        setClaimed(true);
        NotifyUser({ type: "success", message: "Transaction Success" });
      } catch (error) {
        console.log(error);
        setLoading((obj) => ({ ...obj, claim: false }));

        setProgress((progress) => ({ ...progress, claim: false }));
        let message =
          "execution reverted: You are not whitelisted or have already claimed";
        NotifyUser({
          type: "error",
          message:
            error.reason === message
              ? "Your wallet is not whitelisted"
              : error.reason,
        });
      }
    }
  };

  if (loading.whitelist) {
    return (
      <div className='dapp-loading-container'>
        <CircularProgress id='loading' />
      </div>
    );
  }

  const twitterContent = `I just earned $Earth from @solarpunkdao for my contribution towards making the #Refi dream a tangible reality.
  
Let's GROW 🌞💚`;

  return !progress.claim ? (
    <>
      <div className='dapp-trade-container'>
        {/* <p className='dapp-trade-title'>Mint $Earth by passing $Dai</p> */}

        {progress.whitelisted ? (
          <div className='dapp-claim-message-container'>
            <img className='present-icon' src={presentIcon} alt='present' />

            <p className='claim-description'>
              You have earned this $Earth in recognition of your contributions
              towards making the #Refi dream a tangible reality.
              <br />
              <br />
              Consider this a token of our deep gratitude for your commitment to
              the regen mission.
            </p>
            <button className='claim-button' onClick={claimFn}>
              Claim $Earth {loading.claim ? <LoadingOutlined /> : ""}
            </button>
          </div>
        ) : (
          <div className='claim-mint-message-container'>
            <p className='sbt-message'>Your wallet is not whitelisted</p>
          </div>
        )}
      </div>
      {/* 
      {progress.whitelisted ? (
        <div onClick={claimFn} className='dapp-mint-btn-container'>
          <p>
            CLAIM
            {loading.claim ? <LoadingOutlined /> : ""}{" "}
          </p>
        </div>
      ) : null} */}
    </>
  ) : (
    <div className='dapp-trade-container'>
      <p className='twitter-share-msg'>
        {claimed ? "Congrats on Claiming $Earth" : null}
      </p>
      <TwitterShareButton url={twitterContent}>
        <button className='twitter-share-btn'>
          <img className='twitter-share-icon' src={twitter} alt='twitter' />
          Share via Twitter
        </button>
      </TwitterShareButton>
      <p className='claim-description'>
        Stake your $Earth{" "}
        <button onClick={navigate} className='tab-navigate-btn'>
          here
        </button>{" "}
        to earn APY, fund net zero infrastructure and seed the solarpunk
        paradigm.
      </p>
    </div>
  );
}
