import "./tokenomics.css";
import { Video } from "../../Components/Video/video";

export default function Tokenomics() {
  let content = (
    <div className="tokenomics-container">
      <p>TOKENOMICS</p>
    </div>
  );

  return (
    <div className="tokenomicsPage">
      <div className="about-video-container">
        <Video
          src="/assets/tokenomics-video.mp4"
          iframe={false}
          video={true}
          content={content}
          className="about-video"
          poster="/assets/tokenomics-placeholder.jpg"
        />
      </div>

      <div className="content">
        <h3 className="content-heading-1">$Earth has been created to -</h3>

        <div className="tokenomics-feature-container">

          <div className="tokenomics-ind-feature-container-1">
            <div className="tokenomics-ind-feature-container-1-overlay"  style={{backgroundImage:`url('/assets/homePageCoverPic-2.jpg')`}}></div>
          <i className="fa-regular fa-money-bill-1"></i>
            <p>
              Incentivize and mobilize capital globally to allocate towards
              climate solutions.
            </p>
          </div>
          <div className="tokenomics-ind-feature-container-2">
            <div className="tokenomics-ind-feature-container-2-overlay"  style={{backgroundImage:`url('/assets/homePageCoverPic-2.jpg')`}}></div>
          <i className="fa-solid fa-snowman"></i>
            <p>
              Accurately value climate solutions and the positive externalities
              generated by them.
            </p>
          </div>
          <div className="tokenomics-ind-feature-container-3">
            <div className="tokenomics-ind-feature-container-3-overlay"  style={{backgroundImage:`url('/assets/homePageCoverPic-2.jpg')`}}></div>
          <i className="fa-solid fa-snowflake"></i>
          <i className="fa-solid fa-sun"></i>
            <p>
              Use as a medium of exchange to build the solarpunk paradigm for
              the future.
            </p>
          </div>
        </div>
        <br/>
        <br/>
        <h3 className="content-heading-2">
          Keeping this in mind, tokenomics for $Earth have been designed such
          that -
        </h3>

        <div className="keypoints-container">
          <p>
            · New $Earth is only minted in{" "}
            <span className="org-color-highlight">exchange</span> of $DAI at the
            protocol.
          </p>

          <p>
            · Price at which $Earth is minted is{" "}
            <span className="org-color-highlight">flexible</span> to adapt to
            market conditions, confidence in the protocol, and perception of the
            treasury's value.
          </p>

          <p>
            · APY is a direct function of amount of $Earth minted + real yield
            from project + actual demand.{" "}
            <span className="org-color-highlight">Not inflation.</span>
          </p>
          <p>
            · Creating a{" "}
            <span className="org-color-highlight">range bound mechanism</span>{" "}
            that ensures $earth price experiences a soft floor and ceiling.
          </p>
        </div>

        <p>
          Since this is one of the first digital currencies that is going to be
          backed by a variety of real world climate solutions we intend to
          proceed with{" "}
          <span className="blk-color-highlight">calm, care and caution.</span>{" "}
          More than happy to sacrifice short term hype for long term
          sustainability. Mechanics have been designed to ensure that the
          currency value is strongly correlated to treasury backing to ensure we
          dont ever get carried away by bull market shenanigans only to have
          bear market hangovers later. How we manage to do this will become
          clear with our mechanics and their interactions.
        </p>

        <h3 className="content-heading-3">$Earth token -</h3>
        <p>
          Token which all solarpunks will be incentivized to mint directly form
          the protocol. $Earth's purpose is to funnel capital into climate
          solutions and serve as a unit of account representing the value of
          these projects in its treasury. Its goal is to enable price discovery
          of positive externalities while serving as a medium of exchange for
          the solarpunk paradigm.
        </p>

        {/* <img
          className="tokenmonics-img1"
          src="/assets/tokenomics-img-1.png"
          alt="tokenmonics-img"
        /> */}

        <h3 className="content-heading-3">Intrinsic Value (IV)</h3>

        <p>
          Every $Earth token is backed by its treasury holdings in climate
          solutions. These hard assets provide the Intrinsic Value of $Earth.
          This value will serve as a reference point only and will not be
          defended until the point the DAO is able to partly liquidate its
          holdings to ensure the necessary liquidity to defend the value.
        </p>

        <div className="tokenmonics-img2-container">
          <img
            className="tokenmonics-img2"
            src="/assets/tokenomics-img-2.png"
            alt="tokenmonics-img"
          />
        </div>

        <h3 className="content-heading-3">$Earth Premium</h3>
        <p>
          Price at which the protocol lets Solarpunk Nft holders mint $Earth is
          -
        </p>
        <br />

        <h3 className="tokenmonics-desc">
          Intrinsic Value (IV) x Mint Multiple = Mint Price
        </h3>
        <br />
        <p>
          Mint multiple starts at 2 and can be adjusted according to the desired
          monetary policy outcomes. In effect this would translate to Solarpunk
          NFT holders being able to always mint at this price irrespective of
          the AMM price of $Earth. This has the following advantages per our
          smol brain understanding -
        </p>

        <div className="keypoints-container">
          <p>
            · Whenever{" "}
            <span className="blk-color-highlight">
              demand for $Earth on the AMM is{" "}
            </span>{" "}
            <span className="org-color-highlight">high,</span> NFT holders are
            incentivized to mint $Earth directly from the protocol which
            contributes to growing the treasury size. Thereby also
            disincentivizing speculators/short term holders benefitting from
            temporary spikes and profit being captured by traders vs protocol.
          </p>

          <p>
            · Whenever price of $Earth is cheaper on AMM than protocol, all buys
            will be directed there until price{" "}
            <span className="blk-color-highlight">finds</span>{" "}
            <span className="org-color-highlight">parity</span>{" "}
            <span className="blk-color-highlight">with protocol price.</span>
          </p>

          <p>
            · All NFT holders will be able to buy directly from the protocol
            ensuring there is <span className="org-color-highlight">ZERO</span>{" "}
            <span className="blk-color-highlight">slippage while buying</span>,
            while sellers and buyers on AMM will always face slippage
          </p>
          <p>
            · This mechanic also ensures price of $Earth will tentatively
            operate in a <span className="org-color-highlight">range</span>{" "}
            <span className="blk-color-highlight">
              of IV to a slight premium
            </span>{" "}
            on the protocol minting price. Aim of this is provide scope for
            price discovery while ensuring price rise is correlated to treasury
            growth.
          </p>
        </div>

        <h3 className="content-heading-3">$Earth Harvest</h3>

        <p>
          This mechanic is to ensure that IV backing of $Earth never falls, only
          can ever rise. Every time a Nft holders mints $Earth directly from the
          protocol at a specific mint multiple to IV, the protocol gets to
          harvest the remainder such that IV doesnt get diluted.
        </p>

        <h6 className="content-heading-4">Example -</h6>

        <div className="keypoints-container">
          <p>IV - $10</p>

          <p>Mint multiple - 2</p>

          <p>$Earth at protocol sold at $20</p>
          <p>
            Solarpunk NFT holder pays 20 $Dai for 1 $Earth, while IV of 1 $Earth
            is 10 $DAI.
          </p>

          <h3 className="org-color-highlight">
            This lets protocol harvest 1 $Earth without diluting IV.
          </h3>
        </div>

        <p>
          Higher Mint multiples allow us harvest more with each purchase of
          $Earth directly from the protocol. The goal is to increase mint
          multiple gradually as treasury size increases and confidence on $Earth
          and Solarpunk Dao delivering increases
        </p>

      <br/>
      <br/>
        <span className="tokenomics-chart-heading">
          All $Earth harvested through this will be utilized for the following -
        </span>

        <div className="tokenomics-chart-container">
          <img src='/assets/tokenomics-chart.png' alt='chart'/>
        </div>

        <h3 className="tokonomics-content-heading-3">Still curious?</h3>
        <p>
        <a href='https://discord.com/invite/KS8jDkZB5R' rel="noreferrer" target="_blank" className="tokonomics-desc-2">Join our discord server to understand further.</a>
        </p>
        {/* <div
          className="tokenomics-level-container"
          style={{ "backgroundImage": `url('/assets/coverPic-3.png')` }}
        >
          <p>APY for Stakers - 40%</p>
          <p>Parterships / Rewards / IV - 30%</p>
          <p>LP - 20%</p>
          <p>DAO - 10%</p>
        </div> */}
      </div>
    </div>
  );
}
