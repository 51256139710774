import "./home.css";
import { getIcon } from "../../Icon";
import { useEffect, useState } from "react";
// import { FAQ } from "../../Components/FAQ/FAQ";
import { ClimateSolution } from "./Content/climateSolution";
import { Graph } from "./Content/graph";
import { Mechanics } from "./Content/Mechanics";
import { Video } from "../../Components/Video/video";
import { InstrumentalFeatures } from "./Content/instrumentalFeatures";
import { Projects } from "../../Components/Projects/project";
import { SlideShow } from "../../Components/Slideshow";
import { NotifyUser } from "../../router";
import { useNavigate } from "react-router-dom";
import { Animation } from "../../Components/Animation/animation";
import { AnimateText } from "../../Components/Animation/animationText";
import { fetchData } from "../../helper";
import { id_config } from "../../id_config";

export default function Home() {
  const navigate = useNavigate();
  // const [faq,setFaq]=useState('');
  const [loadingPic, setLoadingPic] = useState(true);

  const social = [
    {
      name: "Twitter",
      url: "https://twitter.com/solarpunkdao",
      icon: "/assets/twitter-white.png",
    },
    {
      name: "Discord",
      url: "https://discord.com/invite/KS8jDkZB5R",
      icon: "/assets/discord-white.png",
    },
    {
      name: "Medium",
      url: "https://medium.com/@SolarpunkDao",
      icon: "/assets/medium-white.png",
    },
    {
      name: "GitBook",
      url: "https://solarpunkdao.gitbook.io/earth-coin/",
      icon: "/assets/git-logo-white.png",
    },
  ];

  useEffect(() => {
    // let heading1=document.querySelector('.nav-style-inactive')
    let heading2 = document.querySelector(".nav-heading-1");
    let heading3 = document.querySelector(".nav-heading-2");
    let navBar = document.querySelector(".navBar");

    if (heading2 && heading3 && navBar) {
      heading2.style.color = "white";
      heading3.style.color = "white";

      navBar.style = `
          background-color: #0000004F;
          `;
    }

    // heading1.style.color='white'
  }, []);

  let video2Content = (
    <div className='homePage-video2-description'>
      <p>
        Building the necessary infrastructure for an
        <span> absolute zero</span> emission world.
      </p>
      <div className='video-description-divider'></div>
      <p>
        We intend to be perpetual holding partners
        <br /> in these <span>yield generating</span> climate solutions.
      </p>
    </div>
  );

  let video3Content = (
    <div className='homePage-video3-description'>
      <p>
        <span> 50 Billion tons</span> of CO2 equivalent emissions need to become
        ZERO in the next 30 yrs.
      </p>
      <div className='video-description-divider'></div>
      <p>
        $Earth will let you be at the center of the most
        <span>
          {" "}
          radical <br /> transformation
        </span>{" "}
        ever undertaken by humanity.
      </p>
    </div>
  );

  const [projects, setProjects] = useState("");

  const getData = async () => {
    try {
      const pageId = id_config.projectsPage;
      const websiteId = id_config.website;

      const result = await fetchData({ pageId, websiteId });
      setProjects(result?.Projects);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const images = [
    // "/assets/homePageSlide-1.jpg",
    "/assets/homePageSlide-2.jpg",
    "/assets/homePageSlide-3.jpg",
    // "/assets/homePageSlide-4.jpg",
  ];

  let component = (
    <div className='slideShow-description-container'>
      <p>
        We do not inherit this world from our parents
        <br />
        We borrow it from our children
        <br />
        Let's leave it better than we found it
      </p>
    </div>
  );

  return (
    <div className='homePage' id='home'>
      <div
        className='homePage-coverPic-container'
        style={{ backgroundImage: `url('/assets/homePageCoverPic.png')` }}
      >
        <img
          src='/assets/homePageCoverPic.png'
          className='homePage-coverPic'
          alt='homepage-cover'
        />
        <div className='logo-container'>
          <img
            src={getIcon("logo")}
            style={{ display: loadingPic ? "none" : "block" }}
            onLoad={() => setLoadingPic(false)}
            alt='logo'
          />
          {!loadingPic ? (
            <p className='coverPic-tagLine'>
              Redesigning money to <br />{" "}
              <span onClick={() => navigate("/thesis")}>
                {" "}
                <AnimateText />
              </span>{" "}
            </p>
          ) : (
            ""
          )}
          <div
            className='logo-overlay'
            style={{ display: loadingPic ? "none" : "block" }}
          ></div>
        </div>
        <div className='homePage-cover-social-container'>
          {social.map(({ name, url, icon }, i) => {
            return (
              <a
                key={i}
                href={url}
                target='_blank'
                rel='noreferrer'
                className='cover-ind-social'
              >
                <img src={icon} alt={name} />
              </a>
            );
          })}
        </div>
      </div>
      {/* {!loadingPic ? <Animation/> :""} */}

      <div className='homePage-content'>
        <div
          className='homePage-content-overlay'
          style={{ backgroundImage: `url('/assets/homePageCoverPic-2.jpg')` }}
        ></div>

        <ClimateSolution />
        <Graph />
      </div>

      <Mechanics />

      <div className='homePage-video2-container'>
        <div className='homePage-video2-overlay'></div>

        <Video
          src='/assets/homePage-video-2.mp4'
          content={video2Content}
          iframe={false}
          video={true}
          className='homePage-video2'
          poster='/assets/video2-placeholder.jpg'
        />
      </div>

      <InstrumentalFeatures />

      <div className='homePage-video3-container'>
        <div className='homePage-video3-overlay'></div>

        <img
          src='/assets/forest.jpg'
          alt='forest'
          className='homePage-video2'
        />
        {video3Content}
        {/* <Video
          src="/assets/homePage-video-3.mp4"
          content={video3Content}
          iframe={false}
          video={true}
          className="homePage-video2"
          poster="/assets/video3-placeholder.jpg"
        /> */}
      </div>
      <br />
      <br />
      <Projects projects={projects?.slice(0, 3)} page='homePage' />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {/* <FAQ faq={faq} heading={false} home={true} border={true} /> */}

      <SlideShow
        images={images}
        className='slideShow-container slideshow-home'
        component={component}
      />
    </div>
  );
}
