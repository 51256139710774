import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { notification } from "antd";
import "./index.css";
import BasicLayout from "./Components/BasicLayout";
import About from "./Pages/About";
import Dapp from "./Pages/Dapp";
import Home from "./Pages/Home";
import Thesis from "./Pages/Thesis";
import { TeamList } from "./Pages/Team/teamList";
import Tokenomics from "./Pages/Tokenomics";
import NoPage from "./Pages/NoPage";
import Projects from "./Pages/Projects";
import FAQPage from "./Pages/FAQ/index";
import { ProjectDetail } from "./Pages/Projects/projectPage";

export default function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<BasicLayout />}>
          <Route index element={<Home />} />
          <Route path='purpose' element={<About />} />
          <Route path='projects' element={<Projects />} />
          <Route path='/project/:id' element={<ProjectDetail />} />
          <Route path='thesis' element={<Thesis />} />
          <Route path='tokenomics' element={<Tokenomics />} />
          <Route path='dapp' element={<Dapp />} />
          <Route path='faq' element={<FAQPage />} />
          <Route path='team' element={<TeamList />} />
          <Route path='*' element={<NoPage />} />
          {/* <Route
            path="/admin/projects"
            element={<PrivateRoute children={<AdminDashboard />} />}
          />
          <Route
            path="/admin/project/form"
            element={<PrivateRoute children={<ProjectForm />} />}
          />
          <Route
            path="/admin/project/form/:id"
            element={<PrivateRoute children={<ProjectForm />} />}
          />
          <Route
            path="/admin/team/form"
            element={<PrivateRoute children={<TeamForm />} />}
          />
          <Route
            path="/admin/team"
            element={<PrivateRoute children={<TeamList />} />}
          />
          <Route
            path="/admin/team/form/:id"
            element={<PrivateRoute children={<TeamForm />} />}
          />
          <Route
            path="/admin/faq/form"
            element={<PrivateRoute children={<FAQForm />} />}
  /> */}
        </Route>
      </Routes>
    </div>
  );
}

function PrivateRoute({ children }) {
  const auth = useSelector((state) => state.auth.wallet);

  return auth ? children : <Navigate to='/admin' />;
}

export const NotifyUser = ({ type, message }) => {
  notification[type]({
    placement: "top",
    bottom: 50,
    duration: 3,
    rtl: true,
    message,
  });
};
