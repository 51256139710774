import "./Mechanics.css";

export function Mechanics() {
  let content = [
    {
      name: "Mint NFT",
      description:
        "Own a Solarpunk PFP NFT to be eligible to mint $Earth directly from the protocol",
      img: "/assets/mechanicsImage-1.png",
    },
    {
      name: "Purge petro $$$",
      description: "Mint $Earth from the protocol using $Dai",
      img: "/assets/mechanicsImage-2.png",
    },
    {
      name: "Fund climate solutions",
      description:
        "Dao will allocate your $Dai to verified climate solutions on our portal",
      img: "/assets/mechanicsImage-3.jpg",
    },
    {
      name: "Back $Earth",
      description:
        "NFT of the Foundation Company's holding in climate solutions will serve as backing for $Earth",
      img: "/assets/mechanicsImage-4.jpg",
    },
  ];

  const getStyle = (i) => {
    if (i === 2) {
      return { marginTop: "18rem" };
    } else if (i === 3) {
      return { marginTop: "-18rem" };
    } else {
      return { marginTop: 0 };
    }
  };

  return (
    <div className='Mechanics-container'>
      <div className='Mechanics-heading-container'>
        <p className='Mechanics-heading'>Mechanics</p>
        <p className='Mechanics-heading-description-web'>
          Protocol lets you Purge your Petro dollars for $Earth <br />
          and allocates those dollars towards climate solutions, that serve as
          backing for $Earth
        </p>
        <p className='Mechanics-heading-description-mobile'>
          Protocol lets you Purge your Petro dollars for $Earth and allocates
          those dollars towards climate solutions, that serve as backing for
          $Earth
        </p>
      </div>
      <div className='Mechanics-feature-container'>
        {content &&
          content.map(({ name, description, img }, i) => {
            return (
              <div
                className='Mechanics-ind-feature-container'
                key={i}
                style={getStyle(i + 1)}
              >
                <div className='Mechanics-feature-img-container'>
                  <img src={img} alt='img' />
                  <p>#{i + 1}</p>
                </div>
                <p>{name}</p>
                <p>{description}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
}
