const balance = {
  earth: 0,
  fruit: 0,
  dai: 0,
};

export const balanceReducer = (state = balance, actions) => {
  switch (actions.type) {
    case "EARTH":
      return { ...state, earth: actions.payload };
    case "FRUIT":
      return { ...state, fruit: actions.payload };
    case "DAI":
      return { ...state, dai: actions.payload };
    case "DISCONNECT":
      return balance;
    default:
      return state;
  }
};
