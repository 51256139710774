import { ethers } from "ethers";
import { NotifyUser } from "../../router";
import {
  EarthERC20Token as EarthERC20TokenJson,
  Fruit as FruitJSON,
  StableCoin,
} from "../Dapp/abi";
import { store } from "../../Redux/store";

const { dispatch } = store;

const eartherc20Address = process.env.REACT_APP_EARTH_ERC20_TOKEN_ADDRESS;
const fruitAddress = process.env.REACT_APP_FRUIT_ADDRESS;
const stableCoinAddress = process.env.REACT_APP_STABLE_COIN_ADDRESS;

const getWallet = () => store.getState().auth.wallet;

export const earthAmount = async () => {
  console.log("earth balance");
  const wallet = getWallet();
  if (!wallet) {
    console.log("wallet not found");
    return;
  }

  if (typeof window.ethereum !== undefined) {
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner();
    const contract = new ethers.Contract(
      eartherc20Address,
      EarthERC20TokenJson.abi,
      signer
    );
    try {
      const t = await contract.balanceOf(wallet);
      const result = t / Math.pow(10, 18);
      dispatch({ type: "EARTH", payload: result });
      console.log("earth balance updated");
      return result;
    } catch (error) {
      console.log(error);
    }
  }
};
export const allowance = async () => {
  console.log("fruit balance");
  const wallet = getWallet();
  if (!wallet) {
    console.log("wallet not found");
    return;
  }

  if (typeof window.ethereum !== undefined) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(fruitAddress, FruitJSON.abi, signer);

    try {
      const t = await contract.balanceOf(wallet);
      const result = t / Math.pow(10, 18);
      dispatch({ type: "FRUIT", payload: result });
      console.log("fruit balance updated");
      return result;
    } catch (error) {
      console.log(error);
    }
  }
};
export const getBalance = async () => {
  console.log("dai balance ");
  const wallet = getWallet();
  if (!wallet) {
    console.log("wallet not found");
    return;
  }

  if (typeof window.ethereum !== undefined) {
    const providers = new ethers.providers.Web3Provider(window.ethereum);
    const signer = providers.getSigner();
    const contract = new ethers.Contract(
      stableCoinAddress,
      StableCoin.abi,
      signer
    );
    try {
      const info = await contract.balanceOf(wallet);
      const result = info / Math.pow(10, 18);
      dispatch({
        type: "DAI",
        payload: result,
      });
      console.log("dai balance fetched");
      return result;
    } catch (error) {
      console.log(error);
      NotifyUser({ type: "error", message: error.code });
      return 0;
    }
  }
};
