import faq from "./faq.json";
import { FAQ } from "../../Components/FAQ/FAQ";
import { useState } from "react";
import "./thesis.css";
import { Popup } from "../../Components/Popup/popup";
import { FooterCreditsContent } from "../../Components/BasicLayout/Footer/footer";
import CircularProgress from "@mui/material/CircularProgress";

export default function Thesis() 
{
  const [showCredits, setShowCredits] = useState(false);
  const [loading, setLoading] = useState(true);
  const gitBook="https://solarpunkdao.gitbook.io/earth-coin/";

  return (
    <div className="Thesis-container">
      <div className="Thesis-header">
        {!loading ? <div className="thesis-overlay-1"></div> : ""}
        <div className="thesis-overlay-2">
          <img
            alt='cover'
            style={{ display: loading ? "none" : "block" }}
            onLoad={() => setLoading(false)}
            src="/assets/Thesis-coverPic.png"
          />
          {loading ? (
            <div className="thesis-coverPic-loading-container">
              {" "}
              <CircularProgress id="loading" />{" "}
            </div>
          ) : (
            ""
          )}
        </div>
        {!loading ? <p className="thesis-heading">THESIS</p> : ""}
      </div>

      <div className="thesis-content">
        <p className="thesis-heading-1">Fuel to our FIRE</p>
        <p className="thesis-description">
          Addressing climate change is truly one of those{" "}
          <span className="org-color-highlight">
            once in a generation opportunity.
          </span>{" "}
          The world as we know it will have to change completely if we are to
          address climate change holistically.{" "}
          <span className="blk-color-highlight">
            50 GT of CO2 eqv has to become ZERO in the next 30 years
          </span>{" "}
          and the only way that happens is all the infrastructure supporting
          humanities core needs becomes{" "}
          <span className="org-color-highlight">solarpunk in nature.</span>
        </p>
      </div>
      <img
        className="theis-img"
        src="/assets/thesis-img-1.png"
        alt="coverPic"
      />
      <div className="thesis-content">
        <p className="thesis-desc-heading">
          This transition is no longer a IF but a{" "}
          <span className="org-color-highlight3">WHEN.</span>
        </p>
        <p className="thesis-description">
          Even if we are to believe the righteous intentions of all nation
          states, climate change will never be their to priority because they
          are boundary bound plus lack of incentives for long term action.
        </p>
        <p className="thesis-description">
          We believe that the biggest impediment towards scaling climate action
          globally is capital. $Earth leverages the best of{" "}
          <span className="org-color-highlight">
            crypto economics to incentivize blockchain to mobilize
          </span>{" "}
          capital globally in a capital efficient manner.
        </p>
        <p className="thesis-description">
          Our priority with $Earth is to ensure that viable projects building
          the Solarpunk Infra stack do not face any capital constraints. To this
          effect we intend to be{" "}
          <span className="org-color-highlight">
            perpetual holding partners
          </span>{" "}
          in these projects to the maximum extent possible, structuring deals in
          a way that we always earn pro rata of the projects earnings and are{" "}
          <span className="org-color-highlight">
            equal partners in wins and loses.
          </span>
        </p>

        <p className="thesis-subheading">Sectors we are focussing on -</p>
        <FAQ home={false} heading={false} faq={faq} border={false} />

        {showCredits ? (
          <Popup
            setShowPopup={setShowCredits}
            showPopup={showCredits}
            Component={FooterCreditsContent}
          />
        ) : (
          ""
        )}

        <div className="thesis-chart-container">
          <img src="/assets/thesis-chart.png" alt="chart" />
          <p>
            Approximate annual funding required for each sector to build the
            solarpunk infrastructure stack.
          </p>
          <p onClick={() => setShowCredits(true)}>Source</p>
        </div>

        <p className="thesis-subheading-1">
          Rationale behind sector selection -
        </p>

        <div className="keypoints-container">
          <p className="thesis-description">
            1.They directly address{" "}
            <span className="org-color-highlight">all causes</span> of climate
            change.
          </p>

          <p className="thesis-description">
            2. They cater to humanities{" "}
            <span className="org-color-highlight">core needs</span> - energy,
            food, transport, materials and ecosystems to sustain LIFE
          </p>

          <p className="thesis-description">
            3. All these climate solutions generate tremendous amounts of{" "}
            <span className="org-color-highlight">positive externalities</span>{" "}
            - Clean Air, Healthy soil, Increasing Biodiversity, Nutrient rich
            food, Clean Water, Energy Independence, reducing pollution etc.
          </p>
          <p className="thesis-description">
            4. These real world solutions have undisputed long term value which
            also provide{" "}
            <span className="org-color-highlight">monetary yields</span>{" "}
            uncorrelated to crypto sentiment which ensures strong floor value
            for $Earth.
          </p>
        </div>

        <p className="thesis-heading-1">How?</p>
        <p className="thesis-description">
          We have tried to make it as easy and rewarding for anyone across the
          planet to take constructive climate action by simply buying $Earth and
          using it eventually to build a sustainable, circular, solarpunky
          economy.
        </p>
        <p className="thesis-description">
          Unlike BTC or ETH which are minted as block rewards for validating
          transactions on the blockchain, $Earth is only minted against purged
          $$$ which are used to fund climate solutions. For the first time we
          will have a{" "}
          <span className="org-color-highlight">
            currency that is backed by climate solutions
          </span>{" "}
          which provide essential utility for humanity and not by a shiny metal
          or military forces.
        </p>
        <div className="thesis-img-container">
          <img src="/assets/thesis-img-2.png" alt="cover" />
        </div>
        <p className="thesis-link-highlight">
        <a href={gitBook} rel="noreferrer"  target='_blank'>Ready to go down the rabbit hole?</a>
        </p>
      </div>
    </div>
  );
}
