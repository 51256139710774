import "./project.css";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { NotifyUser } from "../../router";
import { SkeletonScreen } from "../Skeleton/skeleton";
import Slider from "react-slick";

export function Projects({ projects, page, admin, getData }) {
  let headings = [
    "All",
    "Clean Energy",
    "Regenerative Agriculture",
    "Clean Transport",
    "Ecosystem Conservation",
    "Solarpunk Tech",
  ];

  let navigate = useNavigate();

  const [project, setProject] = useState("");

  useEffect(() => {
    if (projects && projects.length) {
      let temp = projects && [...projects];

      temp = temp.filter((data) => data.projectId);

      setProject(temp);
    }
  }, [projects, page, admin]);

  const handleDelete = async (projectId) => {
    const filterProject = projects.filter(
      (data) => data.projectId === projectId
    );

    if (filterProject && filterProject.length) {
      const { _rowNumber: row } = filterProject[0];

      getData();
    }
  };

  const settings = {
    fade: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
  };

  return (
    <div className='projects-container'>
      <p className='project-heading'>Projects backing $Earth</p>

      <p
        className={
          page === "projectPage"
            ? "project-page-heading-description"
            : "project-heading-description"
        }
      >
        Verified list of climate solutions seeking funds.
      </p>

      {page === "projectPage" ? (
        <div className='projectPage-headings'>
          {headings &&
            headings.map((data, i) => {
              return (
                <p style={{ color: i === 0 ? "#FF5E2A" : "black" }} key={i}>
                  {data}
                </p>
              );
            })}
        </div>
      ) : (
        ""
      )}

      {page === "homePage" ? (
        <Slider {...settings} className='slideshow-project-list'>
          {project && project.length
            ? project.map((obj, i) => {
                return (
                  <Project
                    key={i}
                    obj={{ ...obj, admin, handleDelete, navigate, i }}
                  />
                );
              })
            : ""}
        </Slider>
      ) : (
        <ProjectList
          project={project}
          handleDelete={handleDelete}
          admin={admin}
          navigate={navigate}
        />
      )}
    </div>
  );
}

function ProjectList({ project, admin, handleDelete, navigate }) {
  return (
    <div className='project-list'>
      {project && project.length
        ? project.map((obj, i) => {
            return (
              <Project
                key={i}
                obj={{ ...obj, admin, handleDelete, navigate, i }}
              />
            );
          })
        : ""}
    </div>
  );
}

function Project({ obj }) {
  const {
    projectId,
    projectName,
    projectValue,
    subtitle,
    coverPic,
    icon,
    context,
    category,
    country,
    Irr,
    moreInfo,
    admin,
    handleDelete,
    navigate,
    i,
  } = obj;

  const [loading, setLoading] = useState({ coverPic: true, icon: true });

  const onLoad = (obj) => {
    setLoading((loadObj) => ({ ...loadObj, ...obj }));
  };

  return (
    <div className='ind-project'>
      <img
        onLoad={() => onLoad({ coverPic: false })}
        style={{ display: !loading.coverPic ? "block" : "none" }}
        className='project-coverPic'
        src={coverPic ? coverPic : ""}
        onError={() => onLoad({ coverPic: true })}
        alt='project'
      />
      {loading.coverPic ? (
        <SkeletonScreen
          height='250px'
          width='100%'
          variant='rectangular'
          id='skeleton-projectCover'
        />
      ) : (
        ""
      )}

      <div className='project-details-web'>
        <div className='project-row-1'>
          <div className='project-row-1-col-1'>
            <p>Total Project Value</p>
            <p>{projectValue}</p>
          </div>
          <div className='project-row-1-col-2'>
            <div className='project-row-1-col-2-ind-info'>
              <p>category</p>
              <p></p>
              <p>{category}</p>
            </div>

            <div className='project-row-1-col-2-ind-info'>
              <p>country</p>
              <p></p>
              <p>{country}</p>
            </div>

            <div className='project-row-1-col-2-ind-info'>
              <p>Irr</p>
              <p></p>
              <p>{Irr}</p>
            </div>

            {moreInfo &&
              moreInfo.map(({ key, value }, i) => {
                return (
                  <div className='project-row-1-col-2-ind-info' key={i}>
                    <p>{key}</p>
                    <p></p>
                    <p>{value}</p>
                  </div>
                );
              })}
          </div>
        </div>

        <div className='project-row-2'>
          <div className='project-row-2-col-1'>
            <div className='moreInfo-container'>
              <p>project #{i + 1} </p>
              <Dropdown
                admin={admin}
                handleDelete={handleDelete}
                projectId={projectId}
              />
            </div>

            <p>{projectName}</p>
            <p>{subtitle}</p>
          </div>
          <div className='project-row-2-col-2'>
            {icon ? (
              <img
                onLoad={() => onLoad({ icon: false })}
                onError={() => onLoad({ icon: true })}
                style={{
                  display: !loading.icon ? "block" : "none",
                }}
                src={icon ? icon : ""}
                alt='icon'
              />
            ) : (
              ""
            )}
            {loading.icon ? (
              <SkeletonScreen
                height={100}
                width={100}
                variant='circular'
                id='skeleton-projectIcon'
              />
            ) : (
              ""
            )}

            <p>{context}</p>
          </div>
        </div>

        <div
          className='project-row-3'
          onClick={() => navigate(`/project/${projectId}`)}
        >
          <p>view project</p>
        </div>
      </div>

      <div className='project-details-mobile'>
        <div className='project-details-mobile-container-1'>
          <div className='project-row-1'>
            <div className='project-row-1-col-1'>
              <p>Total Project Value</p>
              <p>{projectValue}</p>
            </div>
            <div className='project-row-1-col-2'>
              <div className='project-row-1-col-2-ind-info'>
                <p>category</p>
                <p></p>
                <p>{category}</p>
              </div>

              <div className='project-row-1-col-2-ind-info'>
                <p>country</p>
                <p></p>
                <p>{country}</p>
              </div>

              <div className='project-row-1-col-2-ind-info'>
                <p>Irr</p>
                <p></p>
                <p>{Irr}</p>
              </div>

              {moreInfo &&
                moreInfo.map(({ key, value }, i) => {
                  return (
                    <div className='project-row-1-col-2-ind-info' key={i}>
                      <p>{key}</p>
                      <p></p>
                      <p>{value}</p>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className='project-row-2'>
            <div className='project-row-2-col-1'>
              <div className='moreInfo-container'>
                <p>project #{i + 1} </p>
                <Dropdown
                  admin={admin}
                  handleDelete={handleDelete}
                  projectId={projectId}
                />
              </div>
              <p>{projectName}</p>
              <p>{subtitle}</p>
            </div>
          </div>
        </div>
        <div className='project-details-mobile-container-2'>
          <div className='project-row-2-col-2'>
            {icon ? (
              <img
                onLoad={() => onLoad({ icon: false })}
                style={{
                  display: !loading.icon ? "block" : "none",
                }}
                src={icon ? icon : ""}
                alt='icon'
              />
            ) : (
              ""
            )}
            {loading.icon ? (
              <SkeletonScreen
                height={100}
                width={100}
                variant='circular'
                id='skeleton-projectIcon'
              />
            ) : (
              ""
            )}
            <div>
              <h5>Project by</h5>
              <p>{context}</p>
            </div>
          </div>
        </div>

        <div
          className='project-row-3'
          onClick={() => navigate(`/project/${projectId}`)}
        >
          <p>view project</p>
        </div>
      </div>
    </div>
  );
}

export function Dropdown({ admin, projectId, handleDelete }) {
  let options = ["Edit", "Delete"];

  const [showOption, setShowOption] = useState(false);
  let navigate = useNavigate();

  const ShowMenu = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      ShowMenu.current &&
      showOption &&
      !ShowMenu.current.contains(e.target)
    ) {
      setShowOption(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const handleClick = (data) => {
    if (data === "Edit") {
      navigate(`/admin/project/form/${projectId}`);
    } else if (data === "Delete") {
      handleDelete(projectId);
    }

    setShowOption(false);
  };

  return (
    <div>
      {admin ? (
        <IconButton onClick={() => setShowOption(!showOption)}>
          <MoreVertIcon id='dropdown-icon' />
        </IconButton>
      ) : (
        ""
      )}

      <div className='dropdown-container'>
        {showOption ? (
          <div ref={ShowMenu} className='dropdown'>
            {options.map((data, i) => {
              return (
                <button onClick={() => handleClick(data)} key={i}>
                  {data}
                </button>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
