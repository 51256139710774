import { Projects } from "../../Components/Projects/project";
import "./project.css";
import { useState, useEffect } from "react";
import { Loading } from "../../Components/loading/loading";
import { NotifyUser } from "../../router";
import { fetchData } from "../../helper";
import { id_config } from "../../id_config";

export default function ProjectsPage() {
  const [projects, setProjects] = useState("");
  useEffect(() => {
    const getData = async () => {
      try {
        const pageId = id_config.projectsPage;
        const websiteId = id_config.website;

        const result = await fetchData({ pageId, websiteId });
        setProjects(result?.Projects);
      } catch (error) {
        return;
      }
    };

    getData();
  }, []);

  return (
    <div className="project-page">
      {projects && projects.length ? (
        <>
          <div
            className="project-content-overlay"
            style={{
              backgroundImage: `url('/assets/homePageCoverPic-2.jpg')`,
            }}
          ></div>
          <Projects projects={projects} page="projectPage" />
        </>
      ) : !projects ? (
        <Loading />
      ) : (
        projects && <p className="notFound-message">Projects Not Found</p>
      )}
    </div>
  );
}
