import { useState } from "react";
import { SkeletonScreen } from "../../Components/Skeleton/skeleton";

export function People({ people }) {
  return (
    <div className='people-img-container'>
      {people &&
        people
          .filter((obj) => obj.img)
          .map((obj, i) => {
            return <PeopleImg key={i} obj={obj} />;
          })}
    </div>
  );
}

function PeopleImg({ obj }) {
  const { Role, img, Name } = obj;
  const [loading, setLoading] = useState(false);

  return (
    <div className='ind-people-img-container'>
      <div className='ind-people-img-overlay'></div>
      <p className='people-role'>{Role}</p>
      <img
        src={img}
        alt='profile'
        onLoad={() => setLoading(false)}
        onError={() => setLoading(true)}
        style={{
          display: !loading ? "block" : "none",
        }}
      />
      <div className='people-skeleton-container'>
        {loading ? (
          <SkeletonScreen
            height={400}
            width={"100%"}
            variant='rectangular'
            id='skeleton-people'
          />
        ) : (
          ""
        )}
      </div>
      {!loading.people ? <p className='people-name'>{Name}</p> : ""}
    </div>
  );
}
