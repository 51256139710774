import { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import './video.css'


export function Video({ src, content, iframe, video,className,poster }) 
{
  const[loading,setLoading]=useState(true)
  const [imgLoad,setImgLoad]=useState(true)

  return (
    <>
      {iframe ? (
        <iframe
          src={src}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen=""
          title="pexels-kelly-lacy-9317420"
          data-ready="true"
          className={className}
        />
      ) : (
        ""
      )}

      {video ? (
        <>        
        {imgLoad ? <div className="video-loading-container"> <CircularProgress id='loading' /> </div>: ''}
        {loading ? <img src={poster} style={{display:imgLoad? 'none' : 'block'}} onLoad={()=>setImgLoad(false)} alt='poster' className={className} /> :""}
        <video
          className={className}
          autoPlay={true}
          muted={true}
          playsInline={true}
          loop={true}
          src={src}
          // poster={poster}
          style={{display:loading ? 'none' : 'block'}}
          onLoadedData={()=>{setLoading(false);setImgLoad(false)}}
        />
        
        </>
      ) : (
        ""
      )}

      {!loading ? content : ''}
    </>
  );
}
