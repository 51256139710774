import './instrumentalFeatures.css'

export function InstrumentalFeatures() 
{
  let content = [
    {
      heading: "Valuing natural ecosystems",
      description: "The only truly scarce resource in our solar system",
      icon:'/assets/Valuing natural ecosystems - img.png'
    },
    {
      heading: "Collectively owning infrastructure",
      description: "Assets catering to core needs addressing climate change will be owned by the community",
      icon:'/assets/Collectively owning infrastructure-img.png'
    },
    {
      heading: "Seeding solarpunk paradigm",
      description:
        "Funding projects that are essential to spark the solarpunk revolution",
        icon:'/assets/Seeding solarpunk paradigm-img.png'
    },
    {
      heading: "Incentivizing planet positive actions",
      description:
        "Valuing actions like conservation, regeneration that aren't currently recognised",
      icon:'/assets/Incentivizing planet positive actions-img.png'
    },
    {
      heading: "Bridging climate solutions on chain",
      description: "To serve as building blocks for the Refi ecosystem",
      icon:'/assets/Bridging climate solutions on chain-img.png'
    },
    {
      heading: "Making climate solutions a lucrative asset",
      description: "By making $Earth the best bet in risk/reward/impact terms",
      icon:'/assets/Making climate solutions a lucrative asset-img.png'
    },
  ];

  return (
    <div className="instrumentalFeatures">
      <p className="instrumentalFeatures-heading">$Earth will be instrumental in -</p>
      <div className='instrumentalFeatures-container'>
        {content.map(({ heading, description,icon },i) => {
          return (
            <div className="ind-instrumentalFeatures-container" key={i}>
              <img src={icon} alt="feature-logo" />
              <p>{heading}</p>
              <p>{description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
