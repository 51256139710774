import { useEffect, useRef, useState } from "react";
import { Contract, providers, utils } from "ethers";
import Web3Modal from "web3modal";
import { useSelector } from "react-redux";
import Persona from "persona";
import { NotifyUser } from "../../../router";
import { CircularProgress } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { ethers } from "ethers";

import Soulbound from "../abi/SoulBound.json";
import { id_config } from "../../../id_config";

const exclamationIcon = "/assets/exclamation-mark.png";
const soulboundAddress = process.env.REACT_APP_SOULBOUND_CONTRACT_ADDRESS;
const templateId = process.env.REACT_APP_PERSONA_TEMPLATE_ID;

export function Sbt({ setShowBalance }) {
  const [pageStatus, setPageStatus] = useState({
    verifyStatus: "",
    verify: false,
    whiteListed: false,
    minted: false,
  });
  const [signed, setSigned] = useState(false);
  const [loading, setLoading] = useState({
    verifyLoading: false,
    signLoading: false,
    whitelistLoading: false,
    mintLoading: false,
  });

  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const walletAddressRef = useRef();
  const web3ModalRef = useRef();
  const signatureRef = useRef();
  const account = useSelector((state) => state.auth.wallet);

  const getProviderOrSigner = async (needSigner = false) => {
    try {
      const provider = await web3ModalRef.current.connect();
      const web3Provider = new providers.Web3Provider(provider);

      const { chainId } = await web3Provider.getNetwork();
      console.log(chainId);
      // if (chainId !== 80001) {
      //   window.alert("Change the network to Polygon");
      //   throw new Error("Change network to Polygon");
      // }

      const signer = web3Provider.getSigner();
      walletAddressRef.current = await signer.getAddress();
      if (needSigner) {
        return signer;
      }
      return web3Provider;
    } catch (e) {
      console.log(e);
    }
  };

  async function signData() {
    if (signatureRef.current === undefined) {
      setLoading((loading) => ({ ...loading, signLoading: true }));
      try {
        const signer = await getProviderOrSigner(true);
        const message = utils.solidityKeccak256(
          ["string"],
          ["You confirm you are the owner of this wallet"]
        );

        // Sign the data

        const signature = await signer.signMessage(
          "You confirm you are the owner of this wallet"
        );
        signatureRef.current = signature;
        console.log("Signature", signatureRef.current);
        setSigned(true);
      } catch (e) {
        NotifyUser({
          type: "error",
          message: e.code,
        });
        setLoading((loading) => ({ ...loading, signLoading: false }));

        console.log(e.code);
      }
    }
  }

  const isVerified = async () => {
    setLoading((loading) => ({ ...loading, verifyLoading: true }));
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-api-key": id_config.apiKey,
      },
      body: JSON.stringify({ method: "get" }),
    };
    fetch(`${id_config.apiUrl}/api/cors/${id_config.corsId}`, options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data, "JJJJJJ");
        for (let i = 0; i < response.data.length; i++) {
          const refId = response.data[i].attributes["reference-id"];
          const status = response.data[i].attributes["status"];

          console.log(refId, status);
          if (refId?.toLowerCase() === account?.toLowerCase()) {
            if (status === "approved") {
              setPageStatus((pageStatus) => ({
                ...pageStatus,
                verifyStatus: status,
                verify: true,
              }));
            } else {
              setPageStatus((pageStatus) => ({
                ...pageStatus,
                verifyStatus: status,
                verify: false,
              }));
            }
            break;
          }
        }
        setLoading((loading) => ({ ...loading, verifyLoading: false }));
      })
      .catch((err) => {
        setLoading((loading) => ({ ...loading, verifyLoading: false }));

        console.error(err);
      });
    setShowBalance(true);
  };

  const safeMint = async (e) => {
    e.preventDefault();

    if (!pageStatus.verify) {
      NotifyUser({
        type: "error",
        message: "Please Complete your KYC to mint this SBT",
      });
      return;
    }
    if (typeof window.ethereum !== undefined) {
      setLoading((loading) => ({ ...loading, mintLoading: true }));

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tt = await signer.getAddress();
        let contract = new ethers.Contract(
          soulboundAddress,
          Soulbound.abi,
          signer
        );

        let transaction = await contract.safeMint();
        await transaction.wait();

        isMinted();
        NotifyUser({ type: "success", message: "Successfully Minted!" });
        setLoading((loading) => ({ ...loading, mintLoading: false }));
      } catch (e) {
        console.log(e);
        NotifyUser({ type: "error", message: e.code });
        setLoading((loading) => ({ ...loading, mintLoading: false }));
      }
    }
  };
  const whitelistAddress = async () => {
    if (typeof window.ethereum !== undefined) {
      try {
        setLoading((loading) => ({ ...loading, whitelistLoading: true }));
        const signer = await getProviderOrSigner(true);
        const contract = new Contract(soulboundAddress, Soulbound.abi, signer);
        const tx = await contract.addToWhiteList(account);
        await tx.wait();
        setLoading((loading) => ({ ...loading, whitelistLoading: false }));
        NotifyUser({ type: "success", message: "Address Added to whitelist" });
      } catch (e) {
        NotifyUser({ type: "error", message: e.code });
        console.error(e);
        setLoading((loading) => ({ ...loading, whitelistLoading: false }));
      }
    }
  };

  const handleUnstake = () => {
    console.log(input);
    for (let i in input) {
      if (!input[i]) {
        NotifyUser({ type: "error", message: `${i} is required` });
        return;
      }
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const isValid = emailRegex.test(input.email);

    if (!isValid) {
      NotifyUser({ type: "error", message: `Invalid email` });

      return;
    }

    signData();
  };

  useEffect(() => {
    web3ModalRef.current = new Web3Modal({
      network: "goerli",
      providerOptions: {},
      disableInjectedProvider: false,
    });

    if (account) {
      isVerified();
      isWhitelisted();
      isMinted();
    }
  }, [account]);

  if (loading.verifyLoading) {
    return (
      <div className='dapp-loading-container'>
        <CircularProgress id='loading' />
      </div>
    );
  }

  const isMinted = async () => {
    if (typeof window.ethereum !== undefined) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tt = await signer.getAddress();
        let contract = new ethers.Contract(
          soulboundAddress,
          Soulbound.abi,
          signer
        );

        let result = await contract.tokenMintedAddress(account);
        setPageStatus((status) => ({ ...status, minted: result }));
        console.log({ minted: result });
      } catch (e) {
        console.log(e);
        NotifyUser({ type: "error", message: e.code });
      }
    }
  };

  const isWhitelisted = async () => {
    if (typeof window.ethereum !== undefined) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tt = await signer.getAddress();
        let contract = new ethers.Contract(
          soulboundAddress,
          Soulbound.abi,
          signer
        );

        let result = await contract.whitelistedAddresses(account);
        setPageStatus((status) => ({ ...status, whiteListed: result }));
        console.log({ whiteListed: result });
        result && isMinted();
      } catch (e) {
        console.log(e);
        NotifyUser({ type: "error", message: e.code });
      }
    }
  };

  const handlePersonaCompletion = () => {
    setSigned(false);
    isVerified();
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };
  console.log(pageStatus);
  if (pageStatus.minted) {
    return (
      <div className='sbt-message-container'>
        <p className='message'>Verified and Soulbound Token Minted</p>
      </div>
    );
  }
  // else if (
  //   pageStatus.verifyStatus &&
  //   pageStatus.verifyStatus === "completed" &&
  //   !pageStatus.verify
  // ) {
  //   return (
  //     <div className='sbt-container'>
  //       <br />
  //       <br />
  //       <img className='error-icon' src={exclamationIcon} alt='error' />
  //       <p className='sbt-kyc-comp-description'>Documents being processed</p>
  //     </div>
  //   );
  // }
  else if (
    pageStatus.verifyStatus &&
    pageStatus.verifyStatus === "completed" &&
    !pageStatus.verify &&
    !pageStatus.whiteListed &&
    !pageStatus.minted
  ) {
    return (
      <div className='sbt-container'>
        <br />
        <br />
        <img className='error-icon' src={exclamationIcon} alt='error' />
        <p className='sbt-kyc-comp-description'>
          Once your KYC documents have been approved, your wallet will be
          whitelisted to mint this SBT
        </p>
      </div>
    );
  } else if (pageStatus.verify && !pageStatus.whiteListed) {
    return (
      <div className='sbt-container'>
        <img className='error-icon' src={exclamationIcon} alt='error' />
        <p className='sbt-heading'>
          SOUL BOUND TOKEN <span style={{ color: "#c1272e" }}>NOT FOUND</span>
        </p>
        <p className='sbt-description'>
          In order to mint $Earth you need to own the ‘Soul bound token’ (SBT).
          <br /> It seems lke your wallet does not have the SBT required to mint
          new $Earth.
        </p>
        <p className='sbt-description'>
          You can still trade $Earth at exchanges (please find the TRADE tab).
          <br /> Alternatively you can apply to get whitelisted using the button
          below.
        </p>
        <button className='sbt-button'>GET WHITELISTED </button>
      </div>
    );
  } else if (
    pageStatus.verify &&
    !pageStatus.minted &&
    pageStatus.whiteListed
  ) {
    return (
      <div className='sbt-container'>
        <img className='error-icon' src={exclamationIcon} alt='error' />
        <p className='sbt-heading'>
          SOUL BOUND TOKEN <span style={{ color: "#c1272e" }}>NOT FOUND</span>
        </p>
        <p className='sbt-description'>
          In order to mint $Earth you need to own the ‘Soul bound token’ (SBT).
          <br /> It seems lke your wallet does not have the SBT required to mint
          new $Earth.
        </p>
        <p className='sbt-description'>
          Click the button below to mint your SBT
        </p>
        <button onClick={safeMint} className='sbt-button'>
          MINT SOUL BOUND TOKEN{" "}
          {loading.mintLoading ? <LoadingOutlined /> : null}
        </button>
      </div>
    );
  } else if (
    !pageStatus.verify &&
    (pageStatus.verifyStatus !== "completed" ||
      pageStatus.verifyStatus !== "approved" ||
      !pageStatus.verifyStatus)
  ) {
    return (
      <div className='sbt-kyc-text-container'>
        <p className='sbt-kyc-text'>
          {" "}
          Complete your KYC to mint this Sould Bound NFT.
        </p>
        <div className='sbt-email-field-conatiainer'>
          {/* <label>First Name</label> */}
          <input
            type='text'
            placeholder='first name'
            autoFocus={true}
            name='firstName'
            value={input.firstName}
            onChange={handleChange}
          />
        </div>
        <div className='sbt-email-field-conatiainer'>
          {/* <label>Last Name</label> */}
          <input
            type='text'
            placeholder='last name'
            name='lastName'
            value={input.lastName}
            onChange={handleChange}
          />
        </div>
        <div className='sbt-email-field-conatiainer'>
          {/* <label>Email</label> */}
          <input
            type='text'
            placeholder='email'
            name='email'
            value={input.email}
            onChange={handleChange}
          />
        </div>
        <button onClick={handleUnstake} className='sbt-unstake-button'>
          GET WHITELISTED {loading.signLoading ? <LoadingOutlined /> : null}
        </button>
        <br />

        {!pageStatus.verified &&
        (!pageStatus.verifyStatus ||
          pageStatus.verifyStatus !== "completed" ||
          pageStatus.verifyStatus !== "approved") &&
        signed ? (
          <Verifier
            address={walletAddressRef}
            setLoading={setLoading}
            input={input}
            handlePersonaCompletion={handlePersonaCompletion}
            setShowBalance={setShowBalance}
          />
        ) : null}
      </div>
    );
  }
}

const Verifier = ({
  address,
  setLoading,
  input,
  handlePersonaCompletion,
  setShowBalance,
}) => {
  const [referenceId, setReferenceId] = useState("");

  return (
    <div className='persona-auth-overlay'>
      {!referenceId ? (
        <div className='persona-popup'>
          <Persona.Inquiry
            className='persona'
            onReady={() => {
              setShowBalance(false);
              setLoading((loading) => ({ ...loading, signLoading: false }));
            }}
            templateId={templateId}
            referenceId={address.current}
            fields={{
              nameFirst: input?.firstName,
              nameLast: input?.lastName,
              emailAddress: input?.email,
            }}
            onLoad={() => {
              console.log("Loaded inline");
            }}
            onComplete={({ inquiryId, status, fields }) => {
              setReferenceId(inquiryId);
              handlePersonaCompletion(inquiryId);

              // Inquiry completed. Optionally tell your server about it.
              console.log(`Sending finished inquiry ${inquiryId} to backend`);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
