import { useEffect, useState } from "react";
import { FAQ } from "../../Components/FAQ/FAQ";
import { People } from "./People";
import "./projectPage.css";
import { useParams } from "react-router-dom";
import { Loading } from "../../Components/loading/loading";
import { NotifyUser } from "../../router";
import { SkeletonScreen } from "../../Components/Skeleton/skeleton";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchData } from "../../helper";
import { id_config } from "../../id_config";

export function ProjectDetail() {
  let { id } = useParams();

  const [project, setProject] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const pageId = id_config.projectsPage;
        const websiteId = id_config.website;

        const result = await fetchData({ pageId, websiteId });
        let filterData = result?.Projects.filter(
          ({ projectId }) => id === projectId
        );
        setProject(filterData);
      } catch (error) {
        return;
      }
    };
    getData();
  }, [id]);

  useEffect(() => {
    let navBar = document.querySelector(".navBar");
    let heading1 = document.querySelector(".nav-heading-1");
    let heading2 = document.querySelector(".nav-heading-2");
    let heading3 = document.querySelectorAll(".nav-style-inactive");
    let navBarContainer1 = document.querySelector(".navBar-section-1");
    let navBarContainer2 = document.querySelector(".navBar-section-3");

    heading1.style.color = "white";
    heading2.style.color = "white";
    navBarContainer2.style = `border-left:0.5px solid white;`;
    navBarContainer1.style = `border-right:0.5px solid white;`;

    for (let i = 0; i < heading3.length; i++) {
      if (i === 0) {
        heading3[0].style.color = "#FF5E2A";
      } else {
        heading3[i].style.color = "white";
      }
    }

    navBar.style = `
        background-color: #0000004F;`;

    return () => {
      heading1.style.color = "black";
      heading2.style.color = "black";

      for (let i = 0; i < heading3.length; i++) {
        heading3[i].style.color = "black";
      }

      navBar.style = `
         background-color:white;`;
    };
  }, []);

  const addElement = (value) => {
    const contentBlock = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [coverPicLoad, setCoverPicLoad] = useState(true);

  return (
    <div className='ind-projectPage'>
      {project && project.length ? (
        project.map((e) => {
          const {
            projectId,
            projectName,
            coverPic,
            projectValue,
            returnValue,
            country,
            status,
            dateApproved,
            category,
            description1,
            impact,
            fundingNeeded,
            positiveExternalities,
            icon,
            subText,
            description2,
            people,
            termsOfFunding,
            faq,
          } = e;

          addElement(description1);
          addElement(description2);
          addElement(impact);
          addElement(termsOfFunding);

          return (
            <div key={projectId} className='projectPage'>
              <img
                onLoad={() => setCoverPicLoad(false)}
                onError={() => setCoverPicLoad(true)}
                style={{ display: !coverPicLoad ? "block" : "none" }}
                src={coverPic}
                alt='cover'
                className='projectPage-coverpic'
              />
              {coverPicLoad ? (
                <div className='coverPic-loading'>
                  <CircularProgress id='loading' />
                </div>
              ) : (
                ""
              )}

              <div className='project-container-info-1'>
                <div className='project-container-ind-info-1'>
                  <p>TOTAL PROJECT VALUE</p>
                  <p>{projectValue}</p>
                </div>

                <div className='project-container-ind-info-1'>
                  <p>RETURN</p>
                  <p>{returnValue}</p>
                </div>

                <div className='project-container-ind-info-1'>
                  <p>COUNTRY</p>
                  <p>{country}</p>
                </div>
              </div>

              <div className='project-details-section-1'>
                <p className='project-title'>{projectName}</p>

                <div className='project-container-info-2-web'>
                  <div className='project-container-ind-info-2-key'>
                    <p>STATUS</p>
                    <p>DATE APPROVED</p>
                    <p>CATEGORY</p>
                  </div>

                  <div className='project-container-info-2-divider'></div>

                  <div className='project-container-ind-info-2-value'>
                    <p>{status}</p>
                    <p>{dateApproved}</p>
                    <p>{category}</p>
                  </div>
                </div>

                <div className='project-container-info-2-mobile'>
                  <div className='project-container-ind-info-2-key-mobile'>
                    <p>STATUS</p>
                    <p>{status}</p>
                  </div>
                  <div className='project-container-info-2-divider'></div>

                  <div className='project-container-ind-info-2-key-mobile'>
                    <p>DATE APPROVED</p>
                    <p>{dateApproved}</p>
                  </div>
                  <div className='project-container-info-2-divider'></div>

                  <div className='project-container-ind-info-2-key-mobile'>
                    <p>CATEGORY</p>
                    <p>{category}</p>
                  </div>

                  <div className='project-container-info-2-divider'></div>
                </div>

                <div className='project-description-1'>
                  <Editor
                    toolbarHidden
                    editorState={addElement(description1)}
                    wrapperClassName='demo-wrapper'
                    editorClassName='richText-display'
                    readOnly
                  />
                </div>

                <p className='project-description-heading'>IMPACT</p>
                <div className='project-impact'>
                  <Editor
                    toolbarHidden
                    editorState={addElement(impact)}
                    wrapperClassName='demo-wrapper'
                    editorClassName='richText-display'
                    readOnly
                  />
                </div>
              </div>

              <div className='project-container-info-3'>
                <div className='project-container-info-3-section-1'>
                  <p>FUNDING NEEDED</p>
                  <p>{fundingNeeded}</p>
                </div>
                <div className='project-container-info-3-section-2'>
                  <p>POSITIVE EXTERNALITIES</p>
                  <div className='project-container-info-3-section-2-img-container'>
                    <img src={positiveExternalities} alt='icon' />
                  </div>
                </div>
              </div>

              <div className='project-details-section-1'>
                <div className='project-icon-container'>
                  <img
                    src={icon}
                    onLoad={() => setLoading(false)}
                    onError={() => setLoading(true)}
                    style={{
                      display: !loading ? "block" : "none",
                    }}
                    alt='icon'
                  />
                  {loading ? (
                    <SkeletonScreen
                      height={100}
                      width={100}
                      variant='circular'
                      id='skeleton-projectCove'
                    />
                  ) : (
                    ""
                  )}
                  <p className='project-description-heading'>{subText}</p>
                </div>

                <div className='project-description-2'>
                  <Editor
                    toolbarHidden
                    editorState={addElement(description2)}
                    wrapperClassName='demo-wrapper'
                    editorClassName='richText-display'
                    readOnly
                  />
                </div>
              </div>

              <People people={people} />

              <div className='project-details-section-1'>
                <p className='project-description-heading'>TERMS OF FUNDING</p>
                <div className='project-termsOfFunding'>
                  <Editor
                    toolbarHidden
                    editorState={addElement(termsOfFunding)}
                    wrapperClassName='demo-wrapper'
                    editorClassName='richText-display'
                    readOnly
                  />
                </div>

                <p className='project-description-heading'>
                  Frequently asked questions
                </p>
              </div>
              <FAQ faq={faq} home={false} border={true} />
            </div>
          );
        })
      ) : !project ? (
        <Loading />
      ) : (
        project && <p className='notFound-message'>Projects Not Found</p>
      )}
    </div>
  );
}
