import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useState } from "react";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

export function Trade() {
  const tradeLink = process.env.REACT_APP_TRADE_URL;
  const dexscreenerlink =
    "https://dexscreener.com/polygon/0x40da612b7803892ed002e4f9effd746dc3cf4a5c";
  const [copy, setCopy] = useState(false);
  const address = "0x9F9f149a02Cddc9a8251207cefD3fF774DAF56F6";

  const copyBtn = copy ? (
    <LibraryAddCheckOutlinedIcon />
  ) : (
    <ContentCopyOutlinedIcon />
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(address);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <div className="trade-content-container">
      <div className="dappLink-btn-container">
        <a target="_blank" href={tradeLink} rel="noreferrer">
          Uniswap <OpenInNewIcon />{" "}
        </a>
      </div>
      <div className="trade-address-container">
        <p>$Earth - {address}</p>
        <button onClick={handleCopy}>{copyBtn}</button>
      </div>
      <div className="dappLink-btn-container">
        <a target="_blank" href={dexscreenerlink} rel="noreferrer">
          Dex Screener <OpenInNewIcon />{" "}
        </a>
      </div>
    </div>
  );
}
