import { useEffect, useState } from "react";
import "./dapp.css";
import "./stakefolder/stake.css";
import { Mintcomponent } from "./mintfolder";
import { Stakecomponent } from "./stakefolder";
import "./mintfolder/mint.css";
import "./Sbtfolder/sbt.css";
import "./Connectwallet/connectwallet.css";
import { Connectwallet } from "./Connectwallet";
import { Sbt } from "./Sbtfolder";
import { SlideShow } from "../../Components/Slideshow";
import { Stake } from "./Stake";
import { Summary } from "./Summary";
import { Mint } from "./Mint";
import { ethers } from "ethers";
import { NotifyUser } from "../../router";
import {
  PresaleAllocation as PresaleAllocationJSON,
  Presale as PresaleJSON,
  StableCoin as StableCoinJSON,
  EarthTreasury as EarthTreasuryJSON,
  LockedFruit as LockedFruitJSON,
  EarthStaking as EarthStakingJSON,
} from "./abi";
import Soulbound from "./abi/SoulBound.json";
import { useSelector, useDispatch } from "react-redux";
import { connectWallet } from "./utils";
import { SBT } from "./SBT";
import TermsConditions from "./TermsConditions";
import { Trade } from "./Trade";
import { Claim } from "./Claim";
import { Balance } from "./Earthbalance";

const soulboundAddress = process.env.REACT_APP_SOULBOUND_CONTRACT_ADDRESS;
const EarthTreasuryAddress = process.env.REACT_APP_EARTHTREASURY_ADDRESS;
const PresaleAddress = process.env.REACT_APP_PRESALE_ADDRESS;
const EarthStakingAddress = process.env.REACT_APP_EARTH_STAKING_ADDRESS;

export default function Dapp() {
  useEffect(() => {
    let navBar = document.querySelector(".navBar");
    let heading1 = document.querySelector(".nav-heading-1");
    let heading2 = document.querySelector(".nav-heading-2");
    let heading3 = document.querySelectorAll(".nav-style-inactive");

    heading1.style.color = "white";
    heading2.style.color = "white";

    for (let i = 0; i < heading3.length; i++) {
      heading3[i].style.color = "white";
    }

    navBar.style = `background-color: #0000004F;`;
  }, []);

  const images = [
    "/assets/dappSlideshow-12.png",
    "/assets/dappSlideshow-1.png",
    "/assets/dappSlideshow-2.png",
    "/assets/dappSlideshow-3.png",
    "/assets/dappSlideshow-4.png",
    "/assets/dappSlideshow-5.png",
    "/assets/dappSlideshow-6.png",
    "/assets/dappSlideshow-7.png",
    "/assets/dappSlideshow-8.png",
    "/assets/dappSlideshow-9.png",
    "/assets/dappSlideshow-10.png",
    "/assets/dappSlideshow-11.png",
  ];

  return (
    <SlideShow
      page='dapp'
      images={images}
      className='slideShow-dapp-container slideshow-page'
      component={DappPage()}
    />
  );
}

function DappPage() {
  let label = ["CLAIM", "SBT", "MINT", "STAKE", "TRADE"];
  const [heading, setHeading] = useState(label);
  const [selectedHeading, setSelectedHeading] = useState(heading[0]);
  const account = useSelector((state) => state.auth.wallet);
  const [showBalance, setShowBalance] = useState(true);
  const termsConditions = useSelector((state) => {
    const index = state.auth.accounts.findIndex(
      (obj) => obj.wallet === account
    );
    return index >= 0 ? true : false;
  });
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    earth: "",
    fruit: "",
  });

  const [currentNetwork, setcurrentNetwork] = useState("");

  const networkChanged = async (chainId) => {
    if (typeof window.ethereum !== undefined) {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      provider = await provider.getNetwork();
      provider.name = provider.name === "unknown" ? "localhost" : provider.name;
      setcurrentNetwork(provider.name);

      NotifyUser({
        type: "success",
        message: `Chain-id: ${provider.chainId} \n Network name: ${provider.name}`,
      });
    }
  };

  const isMinted = async (account) => {
    if (typeof window.ethereum !== undefined) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        let contract = new ethers.Contract(
          soulboundAddress,
          Soulbound.abi,
          signer
        );

        let minted = await contract.tokenMintedAddress(account);

        if (minted) {
          setHeading((items) => items.filter((heading) => heading !== "SBT"));
        } else {
          setHeading(label);
        }
      } catch (e) {
        console.log(e);
        NotifyUser({ type: "error", message: e.code });
      }
    }
  };

  // useEffect(() => {
  //   window.ethereum.on("chainChanged", networkChanged);

  //   // return () => {
  //   //   window.ethereum.removeListener("chainChanged", networkChanged);
  //   // };
  // }, []);

  const handleAccountChange = (...args) => {
    connectWallet({
      wallet: "",
      dispatch,
      setShowMenu: "",
      message: "Account Changed",
    });
    setSelectedHeading(label[0]);
  };

  useEffect(() => {
    window.ethereum?.on("accountsChanged", handleAccountChange);
    return () => {
      window.ethereum?.removeListener("accountsChanged", handleAccountChange);
    };
  }, []);

  useEffect(() => {
    account && isMinted(account);
  }, [selectedHeading, account]);

  const earthFunction = async () => {
    if (typeof window.ethereum !== "undefined" && userData.fruit.length) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        EarthStakingAddress,
        EarthStakingJSON.abi,
        signer
      );
      try {
        let earthValue = await contract.accumulationFactor();
        earthValue = earthValue.toString() / Math.pow(2, 64);
        earthValue = parseInt(userData.fruit) * parseInt(earthValue);
        setUserData({
          ...userData,
          earth: earthValue.toString(),
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    // fruitFunction();
    earthFunction();
    // eslint-disable-next-line
  }, [userData.fruit]);

  const handleClick = (data, index) => {
    // if (index === 0 || data === "TRADE") {
    //   setSelectedHeading(data);
    //   return;
    // }
    // let prevPage = heading[index - 1];
    // let pageHeading = prevPage.toLowerCase().split(" ").join("");
    // if (step[pageHeading]) {
    //   setSelectedHeading(data);
    //   return;
    // }
    setSelectedHeading(data);
  };

  const handleStyle = (data, i) => {
    return {
      borderLeft: i === 0 ? "none" : "1px solid black",
      borderTopLeftRadius: i === 0 ? "12px" : "none",
      borderTopRightRadius: data === "TRADE" ? "12px" : "none",
      backgroundColor: data === selectedHeading ? "#ffffff47" : "black",
      color: data === selectedHeading ? "black" : "white",
    };
  };

  const handlePage = (index) => {
    setSelectedHeading(heading[index]);
  };

  return (
    <div className='dapp-container'>
      {/* <p className="coming-soon-text">COMING SOON</p> */}
      {!termsConditions && account ? <TermsConditions /> : null}
      {showBalance && <Balance selectedHeading={selectedHeading} />}
      <div className='dapp'>
        <div className='dapp-tab-container'>
          {heading &&
            heading.map((data, i) => {
              return (
                <button
                  className='ind-dapp-tab-container'
                  style={handleStyle(data, i)}
                  onClick={() => handleClick(data, i)}
                  key={i}
                >
                  <p>{data}</p>
                </button>
              );
            })}
        </div>

        {account && termsConditions ? (
          <>
            {selectedHeading === "SBT" ? (
              <Sbt setShowBalance={setShowBalance} />
            ) : (
              ""
            )}
            {/* {selectedHeading === "SBT" ? <SBT /> : ""} */}

            {selectedHeading === "MINT" ? (
              <Mintcomponent navigate={() => handleClick("SBT")} />
            ) : (
              ""
            )}
            {/* {selectedHeading === "MINT" ? (
              <Mint
                earth={earth}
                totalEarth={totalEarth}
                setHeading={setHeading}
              />
            ) : (
              ""
            )} */}
            {/* {selectedHeading === "STAKE" ? (
              <Stake totalEarth={totalEarth} />
            ) : (
              ""
            )} */}
            {selectedHeading === "STAKE" ? <Stakecomponent /> : ""}
            {selectedHeading === "TRADE" ? <Trade /> : ""}
            {selectedHeading === "CLAIM" ? (
              <Claim navigate={() => handleClick("STAKE")} />
            ) : (
              ""
            )}
          </>
        ) : (
          // <div className='connectWallet-btn-container'>
          //   <button
          //     onClick={() => connectWallet({ dispatch, wallet: account })}
          //   >
          //     Connect Wallet
          //   </button>
          // </div>
          // <div className='dapp'>
          <Connectwallet />
          // </div>
        )}
      </div>
    </div>
  );
}
