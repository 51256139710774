import { useState, useEffect } from "react";
import {
  EarthStaking as EarthStakingJson,
  EarthERC20Token as EarthERC20TokenJson,
  Fruit as FruitJSON,
} from "../abi";
import { NotifyUser } from "../../../router";
import { BigNumber, ethers } from "ethers";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { CircularProgress } from "@mui/material";
import { allowance, earthAmount } from "../balance";

const earthstakingAddress = process.env.REACT_APP_EARTH_STAKING_ADDRESS;
const eartherc20Address = process.env.REACT_APP_EARTH_ERC20_TOKEN_ADDRESS;
const fruitAddress = process.env.REACT_APP_FRUIT_ADDRESS;

export function Stakecomponent() {
  const [stake, setStake] = useState(true);
  return (
    <>
      {stake ? (
        <StakeFunction stake={stake} setStake={setStake} />
      ) : (
        <UnstakeFunction stake={stake} setStake={setStake} />
      )}
    </>
  );
}

function UnstakeFunction({ stake, setStake }) {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const account = useSelector((state) => state.auth.wallet);
  const { fruit: allowanceAmount } = useSelector((state) => state.balance);
  const [result, setResult] = useState(0);
  const [accFactor, setAccFactor] = useState(0);

  const getAccFactor = async () => {
    if (typeof window.ethereum !== undefined) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          earthstakingAddress,
          EarthStakingJson.abi,
          signer
        );

        let value = await contract.accumulationFactor();
        let result = value.toString() / Math.pow(2, 64);
        console.log(result);
        setAccFactor(result);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const formatToTwoDecimalPlaces = (value) => {
    return Number(value).toFixed(2);
  };

  useEffect(() => {
    if (amount) {
      const value = amount * accFactor;
      setResult(value);
    } else {
      setResult(0);
    }
  }, [amount, setResult, accFactor]);

  useEffect(() => {
    if (!account) {
      return;
    }

    allowance();
    getAccFactor();

    const intervalId = setInterval(() => {
      allowance();
      getAccFactor();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [account]);

  const handleChange = ({ target: { name, value } }) => {
    let validatedValue = value.replace("e", "");

    if (/^[+-]?\d+(\.\d*)?$/.test(validatedValue) || value === "") {
      setAmount(validatedValue);
    }
  };

  const unstakeFn = async () => {
    if (!amount) {
      return;
    }

    if (typeof window.ethereum !== "undefined") {
      const Amount = ethers.utils.parseUnits(amount, "ether");
      const AllowanceAmount = allowanceAmount * Math.pow(10, 18);

      if (Number(Amount) > AllowanceAmount) {
        NotifyUser({
          type: "error",
          message: "Amount exceeds fruit balance",
        });
        return;
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const fruitContract = new ethers.Contract(
        fruitAddress,
        FruitJSON.abi,
        signer
      );
      const earthStakingContract = new ethers.Contract(
        earthstakingAddress,
        EarthStakingJson.abi,
        signer
      );

      try {
        setIsLoading(true);
        let info = await fruitContract.increaseAllowance(
          earthstakingAddress,
          Amount
        );
        console.log(info.toString());
        await info.wait();

        info = await earthStakingContract.unstake(
          ethers.BigNumber.from(Amount)
        );
        await info.wait();

        allowance();
        getAccFactor();
        setAmount("");
        setIsLoading(false);

        // Refresh each value

        NotifyUser({ type: "success", message: "Amount Unstaked" });
      } catch (error) {
        setAmount("");
        allowance();
        setIsLoading(false);
        NotifyUser({ type: "error", message: error.code });
        console.log(error);
      }
    }
  };

  return allowanceAmount !== "" ? (
    <>
      <div className='stake-container'>
        <section className='stake-heading-container'>
          <p className='stake-heading'>Unstake $Fruit to get $Earth</p>
          <p className='stake-apy'>APY - 25%</p>
        </section>
        <div className='stake-amount-container'>
          <div className='button-container'>
            <button
              className='stake-button'
              onClick={() => setStake(true)}
              style={{ backgroundColor: stake ? "#eaffe9" : "#fff" }}
            >
              STAKE
            </button>
            <button
              className='unstake-button'
              onClick={() => setStake(false)}
              style={{ backgroundColor: stake ? "#fff" : "#eaffe9" }}
            >
              UNSTAKE
            </button>
          </div>
          <div className='earth-amount-container'>
            <p className='input-label'>Enter $Fruit amount</p>
            <div className='amount-inputfield-container'>
              <input
                className='amount-input'
                onChange={handleChange}
                value={amount}
                type='text'
                name='text'
              />
              <p className='input-tag'>$FRUIT</p>
            </div>
          </div>
          <p className='equalto-symbol'>=</p>
          <div className='earth-amount-container'>
            <p className='input-label'>You will receive</p>
            <div className='amount-inputfield-container'>
              <p className='amount-result'>
                {formatToTwoDecimalPlaces(result)}
              </p>
              <p className='input-tag'>$EARTH</p>
            </div>
          </div>
          <button onClick={unstakeFn} className='stake-confirmation-button'>
            UNSTAKE
            {isLoading ? <LoadingOutlined /> : null}
          </button>
        </div>
      </div>
    </>
  ) : (
    <div className='dapp-loading-container'>
      <CircularProgress id='loading' />
    </div>
  );
}

function StakeFunction({ stake, setStake }) {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const account = useSelector((state) => state.auth.wallet);
  const { earth: earthBalance } = useSelector((state) => state.balance);
  const [result, setResult] = useState(0);
  const [accFactor, setAccFactor] = useState(0);

  const getAccFactor = async () => {
    if (typeof window.ethereum !== undefined) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          earthstakingAddress,
          EarthStakingJson.abi,
          signer
        );
        let value = await contract.accumulationFactor();
        let result = value / Math.pow(2, 64);

        setAccFactor(result);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (amount) {
      const value = amount / accFactor;
      setResult(value);
    } else {
      setResult(0);
    }
  }, [amount, setResult, accFactor]);

  useEffect(() => {
    if (!account) {
      return;
    }

    earthAmount();
    getAccFactor();

    const intervalId = setInterval(() => {
      earthAmount();
      getAccFactor();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [account]);

  const stakeFn = async () => {
    if (!amount) {
      return;
    }

    if (typeof window.ethereum !== "undefined") {
      const Amount = ethers.utils.parseUnits(amount, "ether");
      const EarthBalance = earthBalance * Math.pow(10, 18);

      if (Number(Amount) > EarthBalance) {
        NotifyUser({ type: "error", message: "Amount exceeds earth balance" });
        return;
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const earthStakingContract = new ethers.Contract(
        earthstakingAddress,
        EarthStakingJson.abi,
        signer
      );
      const earthErc20Contract = new ethers.Contract(
        eartherc20Address,
        EarthERC20TokenJson.abi,
        signer
      );
      try {
        setIsLoading(true);
        const allowance = await earthErc20Contract.increaseAllowance(
          earthstakingAddress,
          Amount
        );

        await allowance.wait();

        const info = await earthStakingContract.stake(Amount);
        console.log(info.toString());
        await info.wait();
        earthAmount();
        getAccFactor();

        setIsLoading(false);
        setAmount("");

        // Refresh each value
        // totalEarth();

        NotifyUser({ type: "success", message: "Amount Staked" });
      } catch (error) {
        setIsLoading(false);
        setAmount("");
        earthAmount();
        NotifyUser({ type: "error", message: error.code });
        console.log(error);
      }
    }
  };
  const formatToTwoDecimalPlaces = (value) => {
    return Number(value).toFixed(2);
  };

  const handleChange = ({ target: { name, value } }) => {
    let validatedValue = value.replace("e", "");

    if (/^[+-]?\d+(\.\d*)?$/.test(validatedValue) || value === "") {
      setAmount(validatedValue);
    }
  };

  return earthBalance !== "" ? (
    <>
      <div className='stake-container'>
        <section className='stake-heading-container'>
          <p className='stake-heading'>Stake $Earth to get $Fruit</p>

          <p className='stake-apy'>APY - 25%</p>
        </section>
        <div className='stake-amount-container'>
          <div className='button-container'>
            <button
              className='stake-button'
              onClick={() => setStake(true)}
              style={{ backgroundColor: stake ? "#eaffe9" : "#fff" }}
            >
              STAKE
            </button>
            <button
              className='unstake-button'
              onClick={() => setStake(false)}
              style={{ backgroundColor: stake ? "#fff" : "#eaffe9" }}
            >
              UNSTAKE
            </button>
          </div>
          <div className='earth-amount-container'>
            <p className='input-label'>Enter $Earth amount</p>
            <div className='amount-inputfield-container'>
              <input
                className='amount-input'
                type='text'
                onChange={handleChange}
                name='text'
                value={amount}
              />
              <p className='input-tag'>$EARTH</p>
            </div>
          </div>
          <p className='equalto-symbol'>=</p>
          <div className='earth-amount-container'>
            <p className='input-label'>You will receive</p>
            <div className='amount-inputfield-container'>
              <p className='amount-result'>
                {formatToTwoDecimalPlaces(result)}
              </p>
              <p className='input-tag'>$FRUIT</p>
            </div>
          </div>
          <button onClick={stakeFn} className='stake-confirmation-button'>
            STAKE
            {isLoading ? <LoadingOutlined /> : null}
          </button>
        </div>
      </div>
    </>
  ) : (
    <div className='dapp-loading-container'>
      <CircularProgress id='loading' />
    </div>
  );
}
